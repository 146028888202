import React from "react";
import "../../assets/css/login.css";
import logo from "../../assets/images/Brand.png";
import { withRouter } from "react-router-dom";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import request from "../../core/apiClient/request";
import ChangePassword from "../LoginComponent/ChangePassword";
import LoadingBar from "../../components/LoadingBar/index";
import qs from "qs";

// import Button from "@material-ui/core/Button";
// import DialogContentText from "@material-ui/core/DialogContentText";
// import TextField from "@material-ui/core/TextField";
class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      password: "",
      emailId: "",
      passwordError: "",
      emailIdError: "",
      openForgetPassword: false,
      forgetEmailId: "",
      forgetEmailIdError: "",
      emailSendMessage: false,
      changePassword: false,
      circularProgOpen: false,
    };
  }

  onChangeFieldsHandlerLogin = (e) => {
    if (e.target.id === "email") {
      this.setState({
        emailId: e.target.value,
        emailIdError: "",
      });
    } else if (e.target.id === "password") {
      this.setState({
        password: e.target.value,
        passwordError: "",
      });
    }
  };
  clearForm() {
    this.setState({
      password: "",
      emailId: "",
      passwordError: "",
      emailIdError: "",
      openForgetPassword: false,
      forgetEmailId: "",
      forgetEmailIdError: "",
    });
  }
  handleCloseForgetPasswordDialog = () => {
    this.setState({ openForgetPassword: false });
    this.clearForm();
  };
  handleCloseThankYouMessage = () => {
    this.setState({ emailSendMessage: false });
  };
  handleClickOpenForgetPassword = () => {
    this.setState({
      openForgetPassword: true,
    });
  };
  onClickLogin = async () => {
    let isValid = true;
    let lastAtPos = this.state.emailId.lastIndexOf("@");
    let lastDotPos = this.state.emailId.lastIndexOf(".");
    if (this.state.emailId === "") {
      isValid = false;
      let email = "Please enter the email id";
      this.setState({ emailIdError: email });
    } else if (
      !(
        lastAtPos < lastDotPos &&
        lastAtPos > 0 &&
        this.state.emailId.indexOf("@@") === -1 &&
        lastDotPos > 2 &&
        this.state.emailId.length - lastDotPos > 2
      )
    ) {
      isValid = false;
      let errors = "Please enter the valid email id";
      this.setState({ emailIdError: errors });
    }
    if (this.state.password === "") {
      isValid = false;
      let password = "Please enter the password";
      this.setState({ passwordError: password });
    }
    if (isValid) {
      try {
        this.setState({ circularProgOpen: true });
        let email = this.state.emailId;
        let password = this.state.password;
        let res = await request.post(
          "/admin/login",
          qs.stringify({
            email,
            password,
            // grant_type: "password",
            // scope: "admin",
          }),
          {
            headers: {
              // Authorization: `Basic eyJhbGciOiJIUzI1NiJ9.eyJfaWQiOiI2M2ExNDU1YzgyYTk2NzQ1YjhhNjAxN2YiLCJlbWFpbCI6ImluZm9AZ29sZGVucHJvcGVydHkuaW4iLCJwYXNzd29yZCI6IiQyYiQxMCRhMkNWS0RnbTlrdjVEN29NZ3Q5L3Mua3JnM0VYWVgzTWxudDB4Q2ZYV2d4RGkyMUNhT0xvQyIsImNyZWF0ZWRBdCI6IjIwMjItMTItMjBUMDU6MTc6MTYuNzgzWiIsInVwZGF0ZWRBdCI6IjIwMjItMTItMjBUMDU6MTc6MTYuNzgzWiIsIl9fdiI6MH0.kCAQJIGpN_hdiqv1UI-LNuk8T75NmRGq60rhRQzxv-8`,
              "Content-Type": "application/x-www-form-urlencoded",
            },
          }
        );

        if (res.status >= 200 && res.status < 300 && res?.data?.token) {
          this.setState({ circularProgOpen: false });
          localStorage.setItem("title", "admin");
          // localStorage.setItem(
          //   "userId",
          //   res.data.session[0]
          //     ? res.data.session[0].user
          //     : res.data.session.user
          // );
          localStorage.setItem("sessionKey", res.data.token);
          localStorage.setItem("token", JSON.stringify(res.data));

          this.props.history.push("/");
        } else {
          this.setState({ circularProgOpen: false });
          this.props.handleSnackbar(
            true,
            "The Email And Password That You've Entered Is Incorrect",
            "error"
          );
        }
      } catch (err) {
        this.props.handleSnackbar(
          true,
          "Something went wrong! please try again later.",
          "error"
        );
      }
      this.setState({ circularProgOpen: false });
    }
  };
  handleKeyPress = (e) => {
    if (e.charCode === 13) {
      this.onClickLogin();
    }
  };
  handleKeyPressForgot = (e) => {
    if (e.charCode === 13) {
      this.onClickSendForgetEmail();
    }
  };
  onChangeFieldsHandlerForgetPassword = (e) => {
    if (e.target.id === "forgetEmail") {
      this.setState({
        forgetEmailId: e.target.value,
        forgetEmailIdError: "",
      });
    }
  };
  onClickSendForgetEmail = async () => {
    let isValid = true;
    let lastAtPos = this.state.forgetEmailId.lastIndexOf("@");
    let lastDotPos = this.state.forgetEmailId.lastIndexOf(".");
    if (this.state.forgetEmailId === "") {
      isValid = false;
      let email = "Please enter the email id";
      this.setState({ forgetEmailIdError: email });
    } else if (
      !(
        lastAtPos < lastDotPos &&
        lastAtPos > 0 &&
        this.state.forgetEmailId.indexOf("@@") === -1 &&
        lastDotPos > 2 &&
        this.state.forgetEmailId.length - lastDotPos > 2
      )
    ) {
      isValid = false;
      let errors = "Please enter the valid email id";
      this.setState({ forgetEmailIdError: errors });
    }
    if (isValid) {
      this.setState({ circularProgOpen: true });
      let email = this.state.forgetEmailId;
      let res = await request.post("/resetPassword", {
        email,
      });
      if (res.status === 200) {
        this.setState({ circularProgOpen: false });
        this.handleCloseForgetPasswordDialog();
        this.setState({
          emailSendMessage: true,
        });
      }
      this.setState({ circularProgOpen: false });
    }
  };
  closeChangePasswordForm = () => {
    this.setState({
      changePassword: false,
    });
  };
  componentDidMount() {
    if (localStorage.title) {
      this.props.history.push("/");
    }
  }
  componentWillUnmount() {
    clearTimeout(this.timer);
  }
  render() {
    return (
      <div className="login-main-div">
        <LoadingBar {...this.state}></LoadingBar>
        <div className="login-sub-div">
          <div className="login-header-logo">
            {/* <div className="div-title-name">
              <span> Engineering</span>
              <br />
              <span id="span-title">tomorrow</span>
            </div> */}
            <div
              className="logo-image-div"
              style={{ background: "white", justifyContent: "center" }}
            >
              <img
                src={logo}
                alt="im"
                id="image-logo"
                style={{ height: "5em" }}
              ></img>
            </div>
          </div>
          <div className="login-bodypart">
            <div className="login-sub-body-part">
              {/* <div className="login-animatedText-body">
                <span className="tagLine1">Dream Bigger</span>

                <span className="tagLine2"> Do Bigger</span>
              </div> */}
              <div className="login-form-body">
                <div className="login-tile-name">
                  <span id="login-name">LOGIN</span>
                </div>
                <div className="login-form-bodypart">
                  <div className="input-text">
                    <input
                      type="text"
                      id="email"
                      className="emailId"
                      required
                      onChange={this.onChangeFieldsHandlerLogin}
                      value={this.state.emailId}
                      onKeyPress={this.handleKeyPress}
                      placeholder="Official email id"
                    />
                  </div>
                  <div className="login-error-message">
                    {this.state.emailIdError}
                  </div>
                  <div className="input-text">
                    <input
                      type="password"
                      id="password"
                      className="emailId"
                      required
                      onChange={this.onChangeFieldsHandlerLogin}
                      value={this.state.password}
                      onKeyPress={this.handleKeyPress}
                      placeholder="Password"
                    />
                  </div>
                  <div className="login-error-message">
                    {this.state.passwordError}
                  </div>
                  {/* <div className="forgotPassword-div">
                    <span
                      style={{ cursor: "pointer" }}
                      onClick={this.handleClickOpenForgetPassword}
                    >
                      Forgot password?
                    </span>
                  </div> */}
                  <div className="input-button">
                    <button
                      id="login-submitButton"
                      onClick={() => {
                        this.onClickLogin();
                      }}
                    >
                      LOGIN
                    </button>
                  </div>
                  {/* <button onClick={this.handleCloseThankYouMessage}>
                    check
                  </button> */}
                </div>
              </div>
            </div>
          </div>
          <div className="login-footer-part">
            <div style={{ display: "flex" }}>
              {/* &copy; Danfoss. All Right Reserved. Terms & Policies */}
            </div>
          </div>
        </div>

        <Dialog
          open={this.state.openForgetPassword}
          onClose={this.handleCloseForgetPasswordDialog}
          // aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          className="forgetDialog"
          fullWidth
        >
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <div
              className="dialog-close-button"
              onClick={this.handleCloseForgetPasswordDialog}
            >
              ×
            </div>
          </div>
          <DialogTitle id="alert-dialog-title">
            {"Please Enter Your Email Id"}
          </DialogTitle>
          <DialogContent align="center">
            <input
              type="text"
              id="forgetEmail"
              className="textbox-forget-email"
              required
              onChange={this.onChangeFieldsHandlerForgetPassword}
              value={this.state.forgetEmailId}
              onKeyPress={this.handleKeyPressForgot}
              placeholder="Enter Email Id"
            />

            <div className="dialog-input-error-message">
              <span style={{ color: "red", fontSize: "14px" }}>
                {this.state.forgetEmailIdError}
              </span>
            </div>
          </DialogContent>

          <DialogActions>
            <button
              onClick={() => this.onClickSendForgetEmail()}
              id="Send-email-button"
            >
              SEND{" "}
            </button>
            {/* <Button onClick={this.handleClose1} color="primary" autoFocus>
              Cancel
            </Button> */}
          </DialogActions>
        </Dialog>
        <Dialog
          open={this.state.emailSendMessage}
          onClose={this.handleCloseThankYouMessage}
          // aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          className="forgetDialog"
          fullWidth
          align="center"
        >
          <DialogTitle id="alert-dialog-title" style={{ color: "orangered" }}>
            {"Thank you !!"}
          </DialogTitle>
          <DialogTitle id="alert-dialog-title" style={{ color: "gray" }}>
            {"Please check your email to reset password"}
          </DialogTitle>
          <DialogActions style={{ justifyContent: "center" }}>
            <button
              onClick={this.handleCloseThankYouMessage}
              id="Send-email-button"
            >
              OK
            </button>
          </DialogActions>
        </Dialog>
        <ChangePassword
          open={this.state.changePassword}
          onClose={this.closeChangePasswordForm}
        />
      </div>
    );
  }
}
export default withRouter(Login);
