import React from "react";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import "../../assets/css/DashboardEmployee.css";
import Ongoing from "../../assets/svg/OngoingActivity.svg";
import AwaitingComplete from "../../assets/svg/AwaitingComplete.svg";
import PendingApprovel from "../../assets/svg/PendingApprovel.svg";
import Completed from "../../assets/svg/CompletedActivity.svg";
import request from "../../core/apiClient/request";
import LoadingBar from "../../components/LoadingBar/index";
class HomePage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      circularProgOpen: false,
      awards: 0,
      properties: 0,
      contacts: 0,
      residential: 0,
      farmland: 0,
    };
  }
  componentDidMount() {
    this.loadDashboard();
  }

  loadDashboard = async () => {
    try {
      const { data, status } = await request.get("/api/dashboard", {
        headers: {
          token: localStorage.sessionKey,
          Authorization: ` ${localStorage.getItem("sessionKey")}`,
        },
      });
      if (status >= 200 && status < 300) {
        this.setState({
          awards: data?.awards,
          properties: data?.properties,
          contacts: data?.contacts || 0,
          residential: data?.residential || 0,
          farmland: data?.farmland || 0,
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  render() {
    return (
      <div className="DashboardEmployee-main-div" style={{ padding: "15px" }}>
        <LoadingBar {...this.state}></LoadingBar>
        <span>Dashboard</span>

        <Grid
          container
          spacing={2}
          style={{ marginTop: "2.2%", maxWidth: "100%" }}
        >
          <Grid item xs={6} sm={3}>
            <Paper style={{ boxShadow: "none", borderRadius: "0%" }}>
              <div className="dashboardCard">
                <div className="dashboardCard-contain-one">
                  <img src={Completed} alt="completed"></img>
                </div>
                <span className="dashboardImageDivider"></span>
                <div className="dashboardCard-contain-two">
                  <div>
                    <span className="activityStatus-Number">
                      {this.state.properties || 0}
                    </span>
                  </div>
                  <div>
                    <span className="activityStatus-Title">
                      Total Number of Projects
                    </span>
                  </div>
                </div>
              </div>
            </Paper>
          </Grid>
          <Grid item xs={6} sm={3}>
            <Paper style={{ boxShadow: "none", borderRadius: "0%" }}>
              <div className="dashboardCard">
                <div className="dashboardCard-contain-one">
                  <img src={Ongoing} alt="Ongoing"></img>
                </div>
                <span className="dashboardImageDivider"></span>
                <div className="dashboardCard-contain-two">
                  <div>
                    <span className="activityStatus-Number">
                      {this.state.farmland}
                    </span>
                  </div>
                  <div>
                    <span className="activityStatus-Title">
                      Total Number of Farm Land
                    </span>
                  </div>
                </div>
              </div>
            </Paper>
          </Grid>
          <Grid item xs={6} sm={3}>
            <Paper style={{ boxShadow: "none", borderRadius: "0%" }}>
              <div className="dashboardCard">
                <div className="dashboardCard-contain-one">
                  <img src={AwaitingComplete} alt="AwaitingComplete"></img>
                </div>
                <span className="dashboardImageDivider"></span>
                <div className="dashboardCard-contain-two">
                  <div>
                    <span className="activityStatus-Number">
                      {this.state.residential}
                    </span>
                  </div>
                  <div>
                    <span className="activityStatus-Title">
                      Total Number of Residential Land
                    </span>
                  </div>
                </div>
              </div>
            </Paper>
          </Grid>

          <Grid item xs={6} sm={3}>
            <Paper style={{ boxShadow: "none", borderRadius: "0%" }}>
              <div className="dashboardCard">
                <div className="dashboardCard-contain-one">
                  <img src={PendingApprovel} alt="PendingApprovel"></img>
                </div>
                <span className="dashboardImageDivider"></span>
                <div className="dashboardCard-contain-two">
                  <div>
                    <span className="activityStatus-Number">
                      {this.state.awards || 0}
                    </span>
                  </div>
                  <div>
                    <span className="activityStatus-Title">
                      Awards Recieved
                    </span>
                  </div>
                </div>
              </div>
            </Paper>
          </Grid>
          <Grid item xs={6} sm={3}>
            <Paper style={{ boxShadow: "none", borderRadius: "0%" }}>
              <div className="dashboardCard">
                <div className="dashboardCard-contain-one">
                  <img src={Ongoing} alt="Ongoing"></img>
                </div>
                <span className="dashboardImageDivider"></span>
                <div className="dashboardCard-contain-two">
                  <div>
                    <span className="activityStatus-Number">
                      {this.state.contacts}
                    </span>
                  </div>
                  <div>
                    <span className="activityStatus-Title">Contacts</span>
                  </div>
                </div>
              </div>
            </Paper>
          </Grid>
        </Grid>
      </div>
    );
  }
}
export default HomePage;
