// Appointment
import React from "react";
import "../../assets/css/Home.css";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import request from "../../core/apiClient/request";
import LoadingBar from "../../components/LoadingBar/index";

class Subscriber extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      subscribers: [],
      rowsPerPage: 15,
      page: 0,
      circularProgOpen: false,
      totalCount: 0,
    };
  }
  componentDidMount() {
    this.getSubscribers(0, 15);
  }
  getSubscribers = async (offset = 0, limit = 15) => {
    try {
      this.setState({ circularProgOpen: true });
      let res = await request.get(
        `/subscribe?sort=createdAt DESC&skip=${offset}&limit=${limit}`,
        {
          headers: {
            token: localStorage.sessionKey,
            Authorization: `Bearer ${localStorage.sessionKey}`,
          },
        }
      );
      if (res.status >= 200 && res.status < 300) {
        this.setState({
          subscribers: res.data.subscribe,
          circularProgOpen: false,
          totalCount: res.data.count,
        });
      } else {
        this.props.handleSnackbar(
          true,
          "Something went wrong! please try again later.",
          "error"
        );

        this.setState({ circularProgOpen: false });
      }
    } catch (err) {
      this.props.handleSnackbar(
        true,
        "Something went wrong! please try again later.",
        "error"
      );

      this.setState({ circularProgOpen: false });
    }
  };

  handleChangePage = (event, newPage) => {
    this.setState({ page: newPage });
    this.getSubscribers(
      newPage * this.state.rowsPerPage,
      this.state.rowsPerPage * (newPage + 1)
    );
  };
  handleChangeRowsPerPage = (rowsPerPage) => {
    this.setState({ rowsPerPage });
    this.getSubscribers(0, rowsPerPage);
    this.setState({ page: 0 });
  };
  render() {
    const { subscribers, page, rowsPerPage, totalCount } = this.state;
    return (
      <div className="DashboardEmployee-main-div" style={{ padding: "15px" }}>
        <LoadingBar {...this.state}></LoadingBar>
        <span>Subscriber </span>

        <div className="search-report-refresh-div"></div>
        <div>
          <Paper style={{ width: "100%" }}>
            <TableContainer style={{ maxHeight: 540 }}>
              <Table>
                <TableHead style={{ backgroundColor: "#F4F4F4" }}>
                  <TableRow>
                    <TableCell>Sl. No.</TableCell>
                    <TableCell>Email</TableCell>
                  </TableRow>
                </TableHead>
                {subscribers.length > 0 ? (
                  <TableBody>
                    {subscribers.map((subscriber, index) => (
                      <TableRow
                        className="hover-div"
                        role="checkbox"
                        hover
                        tabIndex={-1}
                        key={index}
                        elevation={4}
                      >
                        <TableCell>
                          {(page * rowsPerPage, page * rowsPerPage) + index + 1}
                        </TableCell>
                        <TableCell>{subscriber.email || ""}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                ) : (
                  <TableBody>
                    <TableRow
                      className="hover-div"
                      role="checkbox"
                      hover
                      tabIndex={-1}
                      elevation={4}
                    >
                      <TableCell></TableCell>
                      <TableCell>Sorry..! No data found</TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
            <TablePagination
              style={{ paddingTop: "5px" }}
              rowsPerPageOptions={[15, 30, 60]}
              component="div"
              count={totalCount}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={this.handleChangePage}
              onChangeRowsPerPage={(event) =>
                this.handleChangeRowsPerPage(event.target.value)
              }
            />
          </Paper>
        </div>
      </div>
    );
  }
}
export default Subscriber;
