import React from "react";
import { withRouter, Switch, Route } from "react-router-dom";
class NestedRotes extends React.Component {
  componentDidMount() {
    if (!localStorage.title) {
      this.props.history.push("/login");
    }
  }
  render() {
    return (
      <Switch>
        {this.props.subPaths &&
          this.props.subPaths.map((elem, routeIndex) => {
            return (
              <Route
                key={routeIndex}
                exact
                path={
                  this.props.path === "/"
                    ? elem?.path
                    : this.props.path + elem?.path
                }
              >
                {<elem.page {...elem} {...this.props} />}
              </Route>
            );
          })}
      </Switch>
    );
  }
}
export default withRouter(NestedRotes);
