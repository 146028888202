import React, { useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";

import DialogTitle from "@material-ui/core/DialogTitle";
import UploadButton from "./UploadButton";
import request from "core/apiClient/request";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const TopProject = ({
  open = false,
  handleClose,
  onSaveBannerInfo,
  isEdit,
  editData,
}) => {
  const [bannerImageUrl, setBannerImageUrl] = useState(null);
  const [bannerImageInfo, setBannerImageInfo] = useState(null);
  const [imageError, setImageError] = useState("");

  const [bannerDescription, setBannerDescription] = useState("");
  const [title, setTitle] = useState("");
  useEffect(() => {
    if (isEdit ===true) {
      setTitle(editData.title);
      setBannerDescription(editData.description);
      setBannerImageUrl(editData.image);
    }
  }, [editData]);
  const onCreateNewBanner = () => {
    if (bannerImageUrl === null) {
      setImageError("please select a image");
    } else {
      onSaveBannerInfo({
        image: bannerImageUrl,
        description: bannerDescription,
        title: title,
      });
      setBannerImageUrl(null);
      setBannerImageInfo(null);
    }
  };

  const handleUploadImage = (e) => {
    const image = e.target.files[0];
    const formData = new FormData();
    formData.append("file", image);
    setBannerImageInfo(image);

    if (image !== undefined) {
      request
        .post("/file", formData, {
          headers: {
            token: localStorage.sessionKey,
            Authorization: ` ${localStorage.getItem("sessionKey")}`,
          },
        })
        .then((res) => {
          setBannerImageUrl(res.data.filePath);
          setImageError("");
        })
        .catch((e) => console.log("errors", e));
    }
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
    >
      <div style={{ width: "clamp(200px,100vw,600px)" }}>
        <DialogTitle id="form-dialog-title"> Add New Project</DialogTitle>
        <DialogContent>
          <div style={{ display: "flex", alignItems: "center" }}>
            <UploadButton onChange={(e) => handleUploadImage(e)} />
            <span style={{ color: "red" }}>{imageError}</span>
            <div
              style={{
                marginLeft: "10px",
                textOverflow: "ellipsis",
                maxWidth: "381px",
                overflow: "hidden",
                whiteSpace: "nowrap",
              }}
            >
              {bannerImageInfo?.name}
            </div>
          </div>
          <TextField
            margin="dense"
            placeholder="Title of the Project"
            label="Add Top Projects"
            type="text"
            style={{ marginTop: "35px" }}
            fullWidth
            value={bannerDescription}
            onChange={(e) => setBannerDescription(e.target.value)}
          />
       
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={onCreateNewBanner} color="primary">
            {isEdit ? "Update" : "Add"}
          </Button>
        </DialogActions>
      </div>
    </Dialog>
  );
};

export default TopProject;

// import React, { useState } from "react";
// import Button from "@material-ui/core/Button";
// import TextField from "@material-ui/core/TextField";
// import Dialog from "@material-ui/core/Dialog";
// import DialogActions from "@material-ui/core/DialogActions";
// import DialogContent from "@material-ui/core/DialogContent";

// import DialogTitle from "@material-ui/core/DialogTitle";
// import UploadButton from "./UploadButton";
// import request from "core/apiClient/request";

// const TopProject = ({ open = false, handleClose, onSaveBannerInfo }) => {
//   const [bannerImageUrl, setBannerImageUrl] = useState(null);
//   const [bannerImageInfo, setBannerImageInfo] = useState(null);
//   const [imageError, setImageError] = useState("");

//   const [bannerDescription, setBannerDescription] = useState("");

//   const onCreateNewBanner = () => {
//     if (bannerImageUrl === null) {
//       setImageError("please select a image");
//     } else {
//       onSaveBannerInfo({
//         image: bannerImageUrl,
//         description: bannerDescription,
        
//       });
//       setBannerImageUrl(null);
//       setBannerImageInfo(null);
//     }
//   };

//   const handleUploadImage = (e) => {
//     const image = e.target.files[0];
//     const formData = new FormData();
//     formData.append("file", image);
//     setBannerImageInfo(image);

//     if (image !== undefined) {
//       request
//         .post("/file", formData, {
//           headers: {
//             token: localStorage.sessionKey,
//             Authorization: ` ${localStorage.getItem('sessionKey')}`,
//           },
//         })
//         .then((res) => {
//           setBannerImageUrl(res.data.filePath);
//           setImageError("");
//         })
//         .catch((e) => console.log("errors", e));
//     }
//   };

  

//   return (
//     <Dialog
//       open={open}
//       onClose={handleClose}
//       aria-labelledby="form-dialog-title"
//     >
//       <div style={{ width: "clamp(200px,100vw,600px)" }}>
//         <DialogTitle id="form-dialog-title"> Add New Banner</DialogTitle>
//         <DialogContent>
//           <div style={{ display: "flex", alignItems: "center" }}>
//             <UploadButton onChange={(e) => handleUploadImage(e)} />
//             <span style={{ color: "red" }}>{imageError}</span>
//             <div
//               style={{
//                 marginLeft: "10px",
//                 textOverflow: "ellipsis",
//                 maxWidth: "381px",
//                 overflow: "hidden",
//                 whiteSpace: "nowrap",
//               }}
//             >
//               {bannerImageInfo?.name}
//             </div>
//           </div>
//           <TextField
//             margin="dense"
//             placeholder="Add Description"
//             label="Description"
//             type="text"
//             style={{ marginTop: "35px" }}
//             fullWidth
//             onChange={(e) => setBannerDescription(e.target.value)}
//           />
//         </DialogContent>
//         <DialogActions>
//           <Button onClick={handleClose}>Cancel</Button>
//           <Button onClick={onCreateNewBanner} color="primary">
//             Add
//           </Button>
//         </DialogActions>
//       </div>
//     </Dialog>
//   );
// };

// export default TopProject;