import UploadFile from "components/Upload/UploadFile";
import React, { useEffect, useState } from "react";
import "../../assets/css/CreateProject.css";
import { Button, Input, message, Select } from "antd";
import { useHistory, useLocation } from "react-router-dom";
import { postNewProject, updateNewProject } from "core/apiClient/apiFunctions";
import EditIcon from "@material-ui/icons/Edit";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import TextField from "@material-ui/core/TextField";
import DialogTitle from "@material-ui/core/DialogTitle";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import UploadButton from "./UploadButton";
import request from "core/apiClient/request";


export default function CreateProject() {
  const { TextArea } = Input;
  const { state } = useLocation();
  const locations = useLocation();
  console.log(locations, "this is location")

  const onChange = (value) => {
    console.log(`selected ${value}`);
    setProjectState(value);
  };
  const onSearch = (value) => {
    console.log("search:", value);
  };

  const navigate = useHistory();

  const [id, setId] = useState();
  const [projectName, setProjectName] = useState("");
  const [projectDescription, setProjectDescription] = useState("");
  const [location, setLocation] = useState("");
  const [projectDetails, setprojectDetails] = useState([]);
  const [units, setUnits] = useState([]);
  const [text, setText] = useState("");
  const [unitText, setUnitText] = useState([]);
  const [unitTitle, setUnitTitle] = useState("");
  const [amenities, setAmenities] = useState([]);
  const [amenitiesTitle, setAmenitiesTitle] = useState("");
  const [localities, setLocalities] = useState([]);
  const [localitieName, setLocalitieName] = useState("");
  const [localitieDistance, setLocalitiesDistance] = useState("");
  const [projectState, setProjectState] = useState("");
  const [brochure, setBrochure] = useState([]);
  const [image, setImage] = useState();
  const [logo, setLogo] = useState([]);
  const [uploadedImageUrl, setUploadedImageUrl] = useState("");
  const [isEdit, setIsEdit] = useState(false);
  const [eachAbout, setEachAbout] = useState();
  const [updateData, setUpdatedData] = useState([]);
  const [updatedText, setUpdatedText] = useState();
  const [editIndex, setEditIndex] = useState();
  const [openUnits, setOpenUnits] = useState(false);
  const [eachUnits, setEachUnits] = useState();
  const [imageError, setImageError] = useState("");
  const [bannerImageUrl, setBannerImageUrl] = useState(null);
  const [bannerImageInfo, setBannerImageInfo] = useState(null);
  const [unitEditText, setUnitEditText] = useState([]);
  const [unitEditTitle, setUnitEditTitle] = useState("");
  const [openAmentities, setOpenAmentities] = useState(false);
  const [eachAmentities, setEachAmentities] = useState();
  const [openLocality, setOpenLocality] = useState(false);
  const [eachLocality, setEachLocality] = useState();



  useEffect(() => {
    if (locations.search !== "") {
      console.log("no data")
    } else {
      localStorage.removeItem("editData");

    }
  }, [locations.search, uploadedImageUrl]);

  useEffect(() => {
    let data = localStorage.getItem("editData");
    let fdata = JSON.parse(data) || {};

    if (Object.keys(fdata).length > 0) {
      setProjectName(fdata?.title);
      setProjectState(fdata?.type)
      setProjectDescription(fdata?.description);
      setLocation(fdata?.location);
      setprojectDetails(fdata?.about);
      setUnits(fdata?.units);
      setUnitTitle(fdata?.unitTitle);
      setAmenities(fdata?.amenities);
      setLocalities(fdata?.localities);
      setId(fdata?._id);
      setUpdatedData(fdata?.about)
      setBrochure(fdata?.brochure)
      setLogo(fdata?.logo)
    }
  }, []);

  const addMoreHandler = () => {
    let data = [...projectDetails];
    console.log(projectDetails, "heyy checkthis")

    let addMore = {
      text: text,
      id: Date.now(),
    };
    data.push(text);
    setprojectDetails(data);
    console.log(data, "heyy checkthisHHHEH")
    setText("");
  };

  const addMoreFilterHandler = (id) => {
    let data = [...projectDetails];
    let filteredData = data.filter((item, index) => index !== id);
    setprojectDetails(filteredData);
  };

  const handleEdit = (id) => {
    setIsEdit(true);
    setEditIndex(id);

    let data = [...projectDetails];


    let filteredData = data.map((item, index) => {
      if (index === id) {
        setEachAbout(item)

      }
    });
  };
  const handleUpdate = () => {
    let updatedData = [...projectDetails];

    let filteredData = updatedData.map((value, index) => {
      if (index === editIndex) {
        console.log(index, editIndex, "y=indexxxxxx")
        return updatedText;
      } else {
        return value;
      }
    })
    setprojectDetails(filteredData);
    setIsEdit(false)

  }

  const handleAboutUpdate = (e) => {
    setUpdatedText(e);
    setEachAbout(e);

  }

  const handleClose = () => {
    setIsEdit(false)
  }

  const textHandler = (event) => {
    setText(event.target.value);
  };
  const handleUploadImage = (e) => {
    const image = e.target.files[0];
    const formData = new FormData();
    formData.append("file", image);
    setBannerImageInfo(image);

    if (image !== undefined) {
      request
        .post("/file", formData, {
          headers: {
            token: localStorage.sessionKey,
            Authorization: ` ${localStorage.getItem("sessionKey")}`,
          },
        })
        .then((res) => {
          setBannerImageUrl(res.data.filePath);
          setBannerImageInfo()
          setImageError("");
          console.log("current resp=>>", res);
        })
        .catch((e) => console.log("errors", e));
    }
  };


  const handleEditUnits = (id) => {
    setOpenUnits(true);
    // setEditIndex(id);
    console.log(id, "this is id")

    let data = [...units];


    let filteredData = data.map((item, index) => {
      if (item._id === id) {
        console.log(item, "this is item")
        setUnitEditText(item.unitDetails)
        setUnitEditTitle(item.unitTitle)
        setBannerImageUrl(item?.unitImage);
        setEachUnits(item)
      }
    });

  }
  const handleEditUnitText = (e) => {
    setUnitEditText(e);
  }
  const hanldeUnitsUpdate = (id) => {
    let updatedData = [...units];

    let filteredData = updatedData.map((value, index) => {
      let unitData = {
        unitDetails: unitEditText,
        unitTitle: unitEditTitle,
        unitImage: bannerImageUrl,
        // id: Date.now(),
      };
      if (value._id === id) {
        console.log(id, value._id, "y=indexxxxxx")
        return unitData;
      } else {
        return value;
      }
    })
    setUnits(filteredData);
    setOpenUnits(false)

  }
  const handleUnitsClose = () => {
    setOpenUnits(false);
  }
  const unitTextHandler = (event) => {
    setUnitText(event.target.value);
  };

  const unitHandler = () => {
    let data = [...units];
    let imageUrl = "";

    let unitData = {
      unitDetails: unitText,
      unitTitle: unitTitle,
      unitImage: uploadedImageUrl[0],
      id: Date.now(),
    };
    data.push(unitData);
    setUnits(data);
    setUnitText("");
    setUnitTitle("");
    setUploadedImageUrl("");
  };

  const unitFilterHandler = (id) => {
    let data = [...units];
    let filteredData = data.filter((item) => item._id !== id);
    setUnits(filteredData);
  };

  const unitTitleHandler = (event) => {
    setUnitTitle(event.target.value);
  };

  const amenitiesTitleHandler = (event) => {
    setAmenitiesTitle(event.target.value);
  };
  const handleEditAmentities = (id) => {
    setOpenAmentities(true);
    // setEditIndex(id);
    console.log(id, "this is id")

    let data = [...amenities];


    let filteredData = data.map((item, index) => {
      if (item._id === id) {
        console.log(item, "this is item")
        // setUnitEditText(item.unitDetails)
        setAmenitiesTitle(item?.amenitiesTitle)
        setUploadedImageUrl(item?.amenitiesImage);
        setBannerImageUrl(item?.amenitiesImage);
        setEachAmentities(item)
      }
    });

  }
  const handleAmentitiesClose = () => {
    setOpenAmentities(false);
  }
  const hanldeAmentitiesUpdate = (id) => {
    let updatedData = [...amenities];

    let filteredData = updatedData.map((value, index) => {
      let unitData = {
        // unitDetails: unitEditText,
        amenitiesTitle: amenitiesTitle,
        amenitiesImage: bannerImageUrl,
        // id: Date.now(),
      };
      if (value._id === id) {
        console.log(id, value._id, "y=indexxxxxx")
        return unitData;
      } else {
        return value;
      }
    })
    setAmenities(filteredData);
    setOpenAmentities(false)

  }
  const amenitiesFilterHandler = (id) => {
    let data = [...amenities];
    let filteredData = data.filter((item) => item._id !== id);
    setAmenities(filteredData);
  };

  const amenitiesHandler = () => {
    let data = [...amenities];
    let amenitiesData = {
      amenitiesTitle: amenitiesTitle,
      amenitiesImage: uploadedImageUrl[0],
      id: Date.now(),
    };
    data.push(amenitiesData);
    setAmenities(data);
    setUploadedImageUrl("");
    setAmenitiesTitle("");
  };

  const localitiesFilterHandler = (id) => {
    let data = [...localities];
    let filteredData = data.filter((item) => item._id !== id);
    setLocalities(filteredData);
  };

  const handleEditLocality = (id) => {
    setOpenLocality(true);
    // setEditIndex(id);
    console.log(id, "this is id")

    let data = [...localities];


    let filteredData = data.map((item, index) => {
      if (item._id === id) {
        console.log(item, "this is item")
        // setUnitEditText(item.unitDetails)
        setLocalitieName(item?.localitiesName)
        setLocalitiesDistance(item?.localitiesDistance)
        setEachLocality(item)
      }
    });

  }
  const handleLocalityClose = () => {
    setOpenLocality(false);
  }
  const hanldeLocalityUpdate = (id) => {
    let updatedData = [...localities];

    let filteredData = updatedData.map((value, index) => {
      let unitData = {
        // unitDetails: unitEditText,
        localitiesName: localitieName,
        localitiesDistance: localitieDistance,
        // id: Date.now(),
      };
      if (value._id === id) {
        console.log(id, value._id, "y=indexxxxxx")
        return unitData;
      } else {
        return value;
      }
    })
    setLocalities(filteredData);
    setOpenLocality(false)

  }

  const localitiesTitleHandler = (event) => {
    setLocalitieName(event.target.value);
  };

  const localitiesDistanceHandler = (event) => {
    setLocalitiesDistance(event.target.value);
  };

  const localitiesHandler = () => {
    let data = [...localities];
    let localitiesData = {
      localitiesName: localitieName,
      localitiesDistance: localitieDistance,
      id: Date.now(),
    };
    data.push(localitiesData);
    setLocalities(data);
    setLocalitieName("");
    setLocalitiesDistance("");
  };
  function handleBrochureUrl(urls) {
    setBrochure(urls[0]);
  }
  const projectNameHandler = (event) => {
    setProjectName(event.target.value);
  };

  const projectDescriptionHandler = (event) => {
    setProjectDescription(event.target.value);
  };

  const projectLocationHandler = (event) => {
    setLocation(event.target.value);
  };


  const submitHandler = () => {
    const aboutStrings = projectDetails.map((each) => each.text);
    const payload = {
      image: image,
      logo: logo?logo[0]:"",
      title: projectName,
      description: projectDescription,
      type: projectState,
      location: location,
      about: aboutStrings,
      units: units,
      amenities: amenities,
      localities: localities,
      brochure: brochure,
      lan: "NA",
      lat: "NA",
    };
    postNewProject(payload)
      .then((res) => {
        if (res.status === 200) {
          message.success("Project added successfully");
          navigate.push("/ProjectListing");
        }
      })
      .catch((e) => {
        console.log("error", e);
      });

  };
  const submitUpdateHandler = () => {
    // const aboutStrings = projectDetails.map((each) => each.text);
    const payload = {
      image: image,
      logo: logo?logo[0]:"", // need to pass the logo - we are not setting the reponse(image url)
      title: projectName,
      description: projectDescription,
      type: projectState,
      location: location,
      about: projectDetails,
      units: units,
      amenities: amenities,
      localities: localities,
      brochure: brochure,
      lan: "NA",
      lat: "NA",
    };


    updateNewProject(payload, id)
      .then((res) => {
        if (res.status === 200) {
          message.success("Project modified successfully");
          navigate.push("/ProjectListing");
          localStorage.removeItem("editData");
        }
      })
      .catch((e) => {
        console.log("error", e);
      });

  }

  return (
    <div className="create-project-cntr">
      <div className="header-cntr">
        <h1>CREATE NEW</h1>
        <p>Projects / Create New</p>
      </div>

      <div className="form-cntr">
        <h3>Create New Project</h3>

        <div className="feilds-cntr">
          <div className="feild">
            <label className="label-text">Property Type</label>
            <Select
              className="select-state"
              showSearch
              placeholder="Select Project State"
              optionFilterProp="children"
              onChange={onChange}
              onSearch={onSearch}
              filterOption={(input, option) =>
                (option?.label ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              value={projectState}
              options={[
                {
                  label: "RESIDENTIAL PLOTS",
                  value: "residential",
                },
                {
                  label: "FARM LAND",
                  value: "farmland",
                },
                {
                  label: "TRENDING PROJECT",
                  value: "trending",
                },
              ]}
            />
          </div>

          <div className="feild">
            <label className="label-text">Project Name</label>
            <Input
              onChange={projectNameHandler}
              value={projectName}
              className="inp-feild"
              placeholder="Enter Project Name.."
            />
          </div>

          <div className="feild">
            <label className="label-text">Project Description</label>

            <TextArea
              // maxLength={6}
              rows={4}
              className="inp-feild"
              placeholder="Enter Project Description.."
              value={projectDescription}
              onChange={projectDescriptionHandler}
            />
          </div>

          <div className="feild">
            <label className="label-text">Location</label>
            <Input
              onChange={projectLocationHandler}
              value={location}
              className="inp-feild"
              placeholder="Enter Location.."
            />
          </div>

          <div className="feild">
            <label className="label-text">About</label>
            <div className="details">
              <div>
                {projectDetails?.map((item, index) => {
                  return (
                    <div className="details-cntr">
                      <p className="descriptiontrext"> {item}</p>

                      <EditIcon
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          handleEdit(index);
                        }}
                      />
                      <Button
                        className=" deleteBtn"
                        onClick={() => {
                          addMoreFilterHandler(index);
                        }}
                      >
                        DELETE
                      </Button>
                    </div>
                  );
                })}
              </div>
              <div className="prj-details-cntr">
                <div className="project-details">
                  <Input
                    className="inp-feild"
                    placeholder="Project Details"
                    value={text}
                    onChange={textHandler}
                  />


                  <Button className="btn-bg" onClick={addMoreHandler}>
                    Add More
                  </Button>
                </div>
              </div>
            </div>
          </div>

          <div className="feild">
            <label className="label-text">Highlights</label>
            <div className="details">
              <div>
                {units.map((item, index) => {
                  return (
                    <div className="details-cntr">
                      <p className="descriptiontrext"> {item.unitTitle}</p>
                      <p className="descriptiontrext">{item.unitDetails}</p>
                      <EditIcon
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          handleEditUnits(item._id);
                        }}
                      />
                      <Button
                        className=" deleteBtn"
                        onClick={() => {
                          unitFilterHandler(item._id);
                        }}
                      >
                        DELETE
                      </Button>
                    </div>
                  );
                })}
              </div>
              <div className="prj-details-cntr">
                <div className="project-details unit-cntr">
                  <Input
                    className=" unit-title"
                    placeholder="Unit Title"
                    value={unitTitle}
                    onChange={unitTitleHandler}
                  />
                  <Input
                    // maxLength={6}
                    rows={4}
                    className=" unit-detail"
                    placeholder="Unit Details"
                    value={unitText}
                    onChange={unitTextHandler}
                  />
                  <div className="upload-cntr">
                    <UploadFile setFunction={setUploadedImageUrl} />
                  </div>
                  <Button className="btn-bg unit-btn" onClick={unitHandler}>
                    Add Unit
                  </Button>
                </div>
              </div>
            </div>
          </div>

          <div className="feild">
            <label className="label-text">Amenities</label>
            <div className="details">
              <div>
                {amenities.map((item, index) => {
                  return (
                    <div className="details-cntr">
                      <p className="descriptiontrext"> {item.amenitiesTitle}</p>

                      <EditIcon
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          handleEditAmentities(item._id);
                        }}
                      />
                      <Button
                        className=" deleteBtn"
                        onClick={() => {
                          amenitiesFilterHandler(item._id);
                        }}
                      >
                        DELETE
                      </Button>
                    </div>
                  );
                })}
              </div>
              <div className="prj-details-cntr">
                <div className="project-details unit-cntr">
                  <Input
                    className=" unit-title"
                    placeholder="Amenities Title"
                    value={amenitiesTitle}
                    onChange={amenitiesTitleHandler}
                  />

                  <div className="upload-cntr">
                    <UploadFile setFunction={setUploadedImageUrl} />
                  </div>
                  <Button
                    className="btn-bg unit-btn"
                    onClick={amenitiesHandler}
                  >
                    Add Amenities
                  </Button>
                </div>
              </div>
            </div>
          </div>

          <div className="feild">
            <label className="label-text">Localities</label>
            <div className="details">
              <div>
                {localities.map((item, index) => {
                  return (
                    <div className="details-cntr">
                      <p className="descriptiontrext"> {item.localitiesName}</p>
                      <p className="descriptiontrext">
                        {item.localitiesDistance}
                      </p>
                      <EditIcon
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          handleEditLocality(item._id);
                        }}
                      />
                      <Button
                        className=" deleteBtn"
                        onClick={() => {
                          localitiesFilterHandler(item._id);
                        }}
                      >
                        DELETE
                      </Button>
                    </div>
                  );
                })}
              </div>
              <div className="prj-details-cntr">
                <div className="project-details unit-cntr">
                  <Input
                    className=" unit-title"
                    placeholder="Localitie name"
                    value={localitieName}
                    onChange={localitiesTitleHandler}
                  />

                  <Input
                    className=" unit-title"
                    placeholder="Localitie distance"
                    value={localitieDistance}
                    onChange={localitiesDistanceHandler}
                  />

                  {/* <div className="upload-cntr">
                    <UploadFile setFunction={setUploadedImageUrl} />
                  </div> */}
                  <Button
                    className="btn-bg unit-btn"
                    onClick={localitiesHandler}
                  >
                    Add Localities
                  </Button>
                </div>
              </div>
            </div>
          </div>

          <div className="feild">
            <label className="label-text">Brochure</label>
            <div className="upload-cntr">
              <UploadFile setFunction={handleBrochureUrl} />
            </div>
          </div>
          <div className="feild">
            <label className="label-text" value={logo}>
              Logo
            </label>
            <div className="upload-cntr">
              <UploadFile setFunction={setLogo} />
            </div>
          </div>

          <div className="feild">
            <label className="label-text" value={image}>
              Images
            </label>
            <div className="upload-cntr">
              <UploadFile setFunction={setImage} />
            </div>
          </div>

          <div>
            <div className="create-btn">
              {!locations.search ? (
                <Button className="btn-bg" onClick={submitHandler}>
                  Create Project
                </Button>
              ) : <Button className="btn-bg" onClick={submitUpdateHandler}>
                Update Project
              </Button>}
              <Dialog
                open={isEdit}
                onClose={handleClose}
                aria-labelledby="form-dialog-title"
              >
                <div style={{ width: "clamp(200px,100vw,600px)" }}>

                  <DialogContent>
                    <DialogTitle>Edit About</DialogTitle>
                    <DialogContent>
                      {/* <TextField
          id="filled-multiline-flexible"
          label="Multiline"
                             multiline
                             maxRows={4}
                                  fullWidth
                       onChange={(e) => handleAboutUpdate(e.target.value)}
                           value={eachAbout}
          /> */}

                      <ReactQuill value={eachAbout} onChange={handleAboutUpdate} />

                    </DialogContent>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button onClick={handleUpdate} color="primary">
                      Update
                    </Button>
                  </DialogActions>
                </div>
              </Dialog>
              {/*++++++++++++++++++++++ Dialog for units +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/}
              <Dialog
                open={openUnits}
                onClose={handleUnitsClose}
                aria-labelledby="form-dialog-title"
              >
                <div style={{ width: "clamp(200px,100vw,600px)" }}>
                  <DialogTitle id="form-dialog-title"> Units</DialogTitle>

                  <DialogContent>
                    <TextField
                      margin="dense"
                      placeholder="Add title"
                      label="Title"
                      type="text"
                      style={{ marginTop: "35px" }}
                      fullWidth
                      onChange={(e) => setUnitEditTitle(e.target.value)}
                      value={unitEditTitle}
                    />
                  </DialogContent>
                  <DialogContent>
                    <TextField
                      margin="dense"
                      placeholder="Add title"
                      label="Unit Details"
                      type="text"
                      style={{ marginTop: "35px" }}
                      fullWidth
                      onChange={(e) => setUnitEditText(e.target.value)}
                      value={unitEditText}
                    />
                  </DialogContent>
                  <DialogContent>

                    <UploadButton onChange={(e) => handleUploadImage(e)} />
                    <span style={{ color: "red" }}>{imageError}</span>
                    {/* <div
              style={{
                marginLeft: "10px",
                textOverflow: "ellipsis",
                maxWidth: "381px",
                overflow: "hidden",
                whiteSpace: "nowrap",
              }}
            >
              {bannerImageInfo?.name}
            </div> */}

                    <img src={bannerImageUrl} style={{ width: "200px", height: "150px" }} />

                  </DialogContent>


                  <DialogActions>
                    <Button onClick={handleUnitsClose}>Cancel</Button>
                    <Button onClick={() => hanldeUnitsUpdate(eachUnits?._id)} color="primary">
                      {openUnits ? "Update" : "Add"}
                    </Button>
                  </DialogActions>
                </div>
              </Dialog>
              {/*++++++++++++++++++++++ Dialog for units +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/}

              {/******** *****************************************************************Edit for amenities*************************************** */}

              <Dialog
                open={openAmentities}
                onClose={handleAmentitiesClose}
                aria-labelledby="form-dialog-title"
              >
                <div style={{ width: "clamp(200px,100vw,600px)" }}>
                  <DialogTitle id="form-dialog-title">Amenities</DialogTitle>

                  <DialogContent>
                    <TextField
                      margin="dense"
                      placeholder="Add title"
                      label="Title"
                      type="text"
                      style={{ marginTop: "35px" }}
                      fullWidth
                      onChange={(e) => setAmenitiesTitle(e.target.value)}
                      value={amenitiesTitle}
                    />
                  </DialogContent>
                  {/* <DialogContent>
         <TextField
            margin="dense"
            placeholder="Add title"
            label="Unit Details"
            type="text"
            style={{ marginTop: "35px" }}
            fullWidth
            onChange={(e) => setUnitEditText(e.target.value)}
           value={unitEditText}
          />
        </DialogContent> */}
                  <DialogContent>

                    <UploadButton onChange={(e) => handleUploadImage(e)} />
                    <span style={{ color: "red" }}>{imageError}</span>
                    {/* <div
              style={{
                marginLeft: "10px",
                textOverflow: "ellipsis",
                maxWidth: "381px",
                overflow: "hidden",
                whiteSpace: "nowrap",
              }}
            >
              {bannerImageInfo?.name}
            </div> */}

                    <img src={bannerImageUrl} style={{ width: "200px", height: "150px" }} />

                  </DialogContent>


                  <DialogActions>
                    <Button onClick={handleAmentitiesClose}>Cancel</Button>
                    <Button onClick={() => hanldeAmentitiesUpdate(eachAmentities?._id)} color="primary">
                      {openUnits ? "Update" : "Add"}
                    </Button>
                  </DialogActions>
                </div>
              </Dialog>



              {/******** *****************************************************************Edit for amenities*************************************** */}
              {/******************************** * Edit for Localities ****************************************************/}


              <Dialog
                open={openLocality}
                onClose={handleLocalityClose}
                aria-labelledby="form-dialog-title"
              >
                <div style={{ width: "clamp(200px,100vw,600px)" }}>
                  <DialogTitle id="form-dialog-title">Amenities</DialogTitle>

                  <DialogContent>
                    <TextField
                      margin="dense"
                      placeholder="Add title"
                      label="Title"
                      type="text"
                      style={{ marginTop: "35px" }}
                      fullWidth
                      onChange={(e) => setLocalitieName(e.target.value)}
                      value={localitieName}
                    />
                  </DialogContent>
                  <DialogContent>
                    <TextField
                      margin="dense"
                      placeholder="Add title"
                      label="Unit Details"
                      type="text"
                      style={{ marginTop: "35px" }}
                      fullWidth
                      onChange={(e) => setLocalitiesDistance(e.target.value)}
                      value={localitieDistance}
                    />
                  </DialogContent>
                  {/* <DialogContent> */}

                  {/* <UploadButton onChange={(e) => handleUploadImage(e)} />
            <span style={{ color: "red" }}>{imageError}</span> */}
                  {/* <div
              style={{
                marginLeft: "10px",
                textOverflow: "ellipsis",
                maxWidth: "381px",
                overflow: "hidden",
                whiteSpace: "nowrap",
              }}
            >
              {bannerImageInfo?.name}
            </div> */}

                  {/* <img src={bannerImageUrl} style={{width:"200px",height:"150px"}}/> */}

                  {/* </DialogContent> */}


                  <DialogActions>
                    <Button onClick={handleLocalityClose}>Cancel</Button>
                    <Button onClick={() => hanldeLocalityUpdate(eachLocality?._id)} color="primary">
                      {openUnits ? "Update" : "Add"}
                    </Button>
                  </DialogActions>
                </div>
              </Dialog>









              {/******************************** * Edit for Localities ****************************************************/}


            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
