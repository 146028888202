import React from "react";
import { withRouter } from "react-router-dom";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
// import { makeStyles } from "@material-ui/core/styles";
import logo from "../../assets/svg/logo.svg";
import Brand from "../../assets/images/Brand.png";
import Logout from "../../assets/svg/logout.svg";
import "../../assets/css/headerBar.css";
import Tooltip from "@material-ui/core/Tooltip";
import request from "../../core/apiClient/request";

class HeaderBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      display: false,
    };
  }

  logoutForm = async () => {
    this.setState({ circularProgOpen: false });
    try {
      let dataEmpty = {};
      let res = await request.post(
        "/signout",
        { dataEmpty },
        {
          headers: {
            token: localStorage.sessionKey,
            Authorization: `Bearer ${localStorage.sessionKey}`,
          },
        }
      );
      if (res.status === 200) {
        this.setState({ circularProgOpen: false });
        localStorage.clear();
        this.props.history.push("/login");
      }
    } catch (err) {
      this.props.history.push("/login");
      localStorage.clear();
      this.setState({ circularProgOpen: false });
    }
    localStorage.clear();
    this.props.history.push("/login");
    this.setState({ circularProgOpen: false });
  };
  render() {
    return (
      <div className="main-header-div">
        <AppBar
          position="fixed"
          style={{
            background: "#fff",
            width: "100%",
            boxShadow: "1px 3px 8px rgba(0,0,0,.4)",
          }}
        >
          <Toolbar
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: " space-between",
            }}
          >
            <div className="imageTitle">
              <div className="imageLogo">
                <img
                  onClick={() => {
                    this.props.history.push("/");
                  }}
                  id="headerCompnayLogo"
                  src={Brand}
                  alt="logo"
                  style={{ width: "9em", height: "auto" }}
                ></img>
              </div>
            </div>
            <div className="notification-profile-div">
              <div className="imageLogo">
                <Tooltip
                  placement="bottom"
                  title={<span className="Tooltip-span">Logout</span>}
                >
                  <img
                    onClick={() => {
                      if (window.confirm("Do you want to logout ?"))
                        this.logoutForm();
                    }}
                    id="logo"
                    className="HeaderLogos"
                    src={Logout}
                    alt="logo"
                  ></img>
                </Tooltip>
              </div>
            </div>
          </Toolbar>
        </AppBar>
      </div>
    );
  }
}

export default withRouter(HeaderBar);
