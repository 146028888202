import React from "react";
import { Route, Router, Switch } from "react-router-dom";
import Routes from "./routes";
import DefaultLayout from "../../components/layouts/layout2/index";
import MySnackbar from "../../components/Snackbar/snackbar";
import request from "../apiClient/request";
import { createBrowserHistory } from "history";

const history = createBrowserHistory();

export default class RoutesPage extends React.Component {
  state = {
    showSnackbar: false,
    snackbarVariant: "info",
    snackbarMessage: "",
  };
  getRoute = (routeObj) => {
    if (routeObj.page)
      return (
        <routeObj.page
          {...routeObj}
          handleSnackbar={this.handleSnackbar}
          {...this.props}
        />
      );
    else if (routeObj.layout)
      return (
        <routeObj.layout
          {...routeObj}
          handleSnackbar={this.handleSnackbar}
          {...this.props}
        />
      );
    else if (!routeObj.page && !routeObj.layout && routeObj.subPaths)
      return (
        <DefaultLayout
          {...routeObj}
          handleSnackbar={this.handleSnackbar}
          {...this.props}
        />
      );
    else return <h4>404 Not Found</h4>;
  };
  updateConnectionStatus = () => {
    if (!navigator.onLine) {
      this.handleSnackbar(true, "No internet connection", "warning");
    } else if (navigator.onLine) {
      this.handleSnackbar();
    }
  };
  handleSnackbar = (
    showSnackbar = false,
    snackbarMessage = "",
    snackbarVariant = "info",
    hideDuration = 3000
  ) => {
    this.setState({ showSnackbar, snackbarMessage, snackbarVariant });
    this.timer = setTimeout(() => {
      this.setState({
        showSnackbar: false,
        snackbarMessage: "",
        snackbarVariant: "info",
      });
    }, hideDuration);
  };

  componentDidMount = () => {
    if (!navigator.onLine) {
      this.handleSnackbar(true, "No internet connection", "warning");
    }
    window.addEventListener("offline", (e) => {
      this.updateConnectionStatus();
    });

    window.addEventListener("online", (e) => {
      this.updateConnectionStatus();
    });
    if (!localStorage.hasOwnProperty("sessionKey")) {
      localStorage.clear();
      history.push("/login");
      return;
    }
    this.loadSessionIsActive();
  };

  loadSessionIsActive = async () => {
    try {
      this.setState({ circularProgOpen: true });
      let res = await request.get(`/user/${localStorage.userId}`, {
        headers: {
          token: localStorage.sessionKey,
          Authorization: `Bearer ${localStorage.sessionKey}`,
        },
      });
      if (res.status === 403) {
        this.handleSnackbar(
          true,
          "Your session is expired please login again.",
          "error"
        );
        this.handleSnackbar();
        this.setState({ circularProgOpen: false });
        localStorage.clear();
        history.push("/login");
      }
    } catch (err) {
      this.handleSnackbar(
        true,
        "Something went wrong! please try again later.",
        "error"
      );
      this.handleSnackbar();
      this.setState({ circularProgOpen: false });
    }
  };
  componentWillMount = () => {
    clearTimeout(this.timer);
  };
  render() {
    return (
      <>
        <Router history={history}>
          <Switch>
            {Routes.map((routeObj, routeObjIndex) => {
              
              return (
                <Route
                  path={routeObj?.path}
                  key={routeObjIndex}
                  handleSnackbar={this.handleSnackbar}
                  {...this.props}
                >
                  {this.getRoute(routeObj)}
                </Route>
              );
            })}
          </Switch>
        </Router>
        <MySnackbar
          open={this.state.showSnackbar}
          variant={this.state.snackbarVariant}
          message={this.state.snackbarMessage}
        ></MySnackbar>
      </>
    );
  }
}
