// import React, { useEffect, useState } from "react";
// import Paper from "@material-ui/core/Paper";
// import Table from "@material-ui/core/Table";
// import TableBody from "@material-ui/core/TableBody";
// import TableCell from "@material-ui/core/TableCell";
// import TableContainer from "@material-ui/core/TableContainer";
// import TableHead from "@material-ui/core/TableHead";
// // import TablePagination from "@material-ui/core/TablePagination";
// import TableRow from "@material-ui/core/TableRow";
// import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
// import EditIcon from "@material-ui/icons/Edit";
// import { Button, Dropdown, Input, TextArea, Select } from "antd";
// import { useHistory } from "react-router-dom";
// import { Pagination } from "antd";

// export default function AmenitiListing() {
//   const navigate = useHistory();
//   const [amenitiListingdata, setAmenitiListingdata] = useState([]);

//   useEffect(() => {
//     let data = localStorage.getItem("amenities");
//     let projectData = JSON.parse(data) || [];
//     setAmenitiListingdata(projectData);
//   }, []);

//   const navigateToPage = () => {
//     navigate.push("/Amenities");
//   };

//   const editHandler = (id) => {
//     let data = [...amenitiListingdata];
//     let filteredData = data.filter((item, index) => {
//       return item.id == id;
//     });
//     localStorage.setItem("editAmenitiesData", JSON.stringify(filteredData));
//     // alert(JSON.stringify(filteredData))
//     navigate.push("/Amenities");
//   };

//   // const editHandler = (id) => {
//   //   let data = [...amenitiListingdata];
//   //   let filteredData = data.filter((item) => item.id == id);

//   //   localStorage.setItem("editAmenitiesData", JSON.stringify(filteredData[0]));
//   //  ;
//   //   navigate.push("/Amenities");
//   // };

//   const deleteHandler = (id) => {
//     let data = [...amenitiListingdata];
//     let filteredData = data.filter((item) => item.id !== id);
//     setAmenitiListingdata(filteredData);
//     localStorage.setItem("amenities", JSON.stringify(data));
//     localStorage.setItem("amenities", JSON.stringify(filteredData));
//   };

//   return (
//     <div>
//       .<p style={{ margin: "1em" }}>Add New Amenities</p>
//       <div
//         className="crt-new-prjct"
//         style={{
//           display: "flex",
//           justifyContent: "flex-end",
//           alignItem: "flex-end",
//         }}
//       >
//         <Button
//           style={{
//             height: "3rem",
//             backgroundColor: "#3f51b5",
//             color: "white",
//             margin: "3rem",
//           }}
//           onClick={navigateToPage}
//         >
//           Create New Amenities
//         </Button>
//       </div>
//       <div>
//         <Paper style={{ width: "100%", backgroundColor: "#F4F4F4" }}>
//           <TableContainer style={{ maxHeight: 540 }}>
//             <Table>
//               <TableHead style={{ backgroundColor: "#F4F4F4" }}>
//                 <TableRow>
//                   <TableCell>Sl.No.</TableCell>
//                   <TableCell>Amenities Title</TableCell>
//                   <TableCell>Images</TableCell>
//                   <TableCell>Action</TableCell>
//                 </TableRow>
//               </TableHead>
//               {amenitiListingdata.length > 0 ? (
//                 <TableBody>
//                   {amenitiListingdata.map((act, index) => (
//                     <TableRow
//                       style={{ backgroundColor: "white" }}
//                       className="hover-div"
//                       role="checkbox"
//                       hover
//                       tabIndex={-1}
//                       key={index}
//                       elevation={4}
//                     >
//                       <TableCell>{index + 1}</TableCell>

//                       <TableCell>{act?.amenitiesTitle}</TableCell>
//                       <TableCell>{act?.image}</TableCell>
//                       <TableCell>
//                         <div
//                           style={{
//                             display: "flex",
//                           }}
//                         >
//                           <EditIcon
//                             style={{ cursor: "pointer" }}
//                             onClick={() => {
//                               editHandler(act.id);
//                             }}
//                           />
//                           <DeleteOutlineIcon
//                             style={{ cursor: "pointer" }}
//                             onClick={() => {
//                               deleteHandler(act.id);
//                             }}
//                           />
//                         </div>
//                       </TableCell>
//                     </TableRow>
//                   ))}
//                 </TableBody>
//               ) : (
//                 <TableBody>
//                   <TableRow
//                     className="hover-div"
//                     role="checkbox"
//                     hover
//                     tabIndex={-1}
//                     elevation={4}
//                   >
//                     <TableCell></TableCell>
//                     <TableCell></TableCell>
//                     <TableCell></TableCell>
//                     <TableCell>Sorry..! No data found</TableCell>
//                     <TableCell></TableCell>
//                     <TableCell></TableCell>
//                     <TableCell></TableCell>
//                   </TableRow>
//                 </TableBody>
//               )}
//             </Table>
//           </TableContainer>
//           <Pagination
//             defaultCurrent={1}
//             total={50}
//             style={{
//               display: "flex",
//               justifyContent: "flex-end",
//               alignItems: "center",
//               height:"3em"
//             }}
//           />
//           ;
//         </Paper>
//       </div>
//     </div>
//   );
// }

import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import DeleteIcon from "@material-ui/icons/Delete";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import ImageUploader from "./ImageUploader";
import request from "../../core/apiClient/request";
import LoadingBar from "components/LoadingBar";
import { Pagination } from "antd";
import EditIcon from "@material-ui/icons/Edit";

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  tableContainer: {
    marginTop: 20,
  },
});

export default function AmenitiListing() {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [bannerData, setBannerData] = useState([]);
  const [circularProgOpen, setCircularProgOpen] = useState(false);
  const [editData, setEditData] = useState({});
  const [isEdit, setIsEdit] = useState(false);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(1);
  function getBanners() {
    setCircularProgOpen(true);
    request
      .get(`/api/amenities?page=${page}&size=10`)
      .then((res) => {
        if (res.status === 200) {
          setBannerData(res.data.amenities);
          setTotal(res.data.total);
          console.log(res.data.amenities);
        } else {
          console.log("something went wrong");
        }
        setCircularProgOpen(false);
      })
      .catch((e) => {
        console.log("something went wrong");
        setCircularProgOpen(false);
      });
  }
  useEffect(() => {
    getBanners();
  }, [page]);
  const onSaveBannerInfo = (details) => {
    console.log("details", details);
    if (isEdit) {
      request
        .patch(
          `/api/amenities/${editData._id}`,
          {
            imageUrl: details.image,
            title: details.description,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: ` ${localStorage.getItem("sessionKey")}`,
            },
          }
        )
        .then((res) => {
          if (res.status === 200) {
            getBanners();
            setOpen(false);
          }
        })
        .catch((e) => console.log("error", e));
    } else {
      request
        .post(
          "/api/amenities",
          {
            imageUrl: details.image,
            title: details.description,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: ` ${localStorage.getItem("sessionKey")}`,
            },
          }
        )
        .then((res) => {
          if (res.status === 200) {
            getBanners();
            setOpen(false);
          }
        })
        .catch((e) => console.log("error", e));
    }
  };

  const onRemoveItem = (id) => {
    request
      .delete(`/api/amenities/${id}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: ` ${localStorage.getItem("sessionKey")}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          getBanners();
        } else {
          console.log("something went wrong");
        }
      })
      .catch((e) => console.log("error", e));
  };
  function handleEdit(id) {
    const tempEditData = bannerData.filter((banner) => id === banner._id)[0];
    setIsEdit(true);
    setEditData(tempEditData);
    setOpen(true);
  }
  return (
    <>
      <LoadingBar circularProgOpen={circularProgOpen} />
      <div
        style={{
          position: "relative",
          marginLeft: "auto",
          marginTop: "30px",
        }}
      >
        <Button
          variant="contained"
          color="primary"
          component="span"
          onClick={() => setOpen(true)}
        >
          Add New Amenities
        </Button>
        <ImageUploader
          open={open}
          handleClose={() => setOpen(false)}
          onSaveBannerInfo={onSaveBannerInfo}
          editData={editData}
          isEdit={isEdit}
        />
      </div>
      <TableContainer component={Paper} className={classes.tableContainer}>
        <Table
          className={classes.table}
          aria-label="simple table"
          showEmptyDataSourceMessage
        >
          <TableHead>
            <TableRow>
              <TableCell>S.N</TableCell>
              <TableCell style={{ marginLeft: "1em" }}>Image</TableCell>
              <TableCell>Amenities Title</TableCell>
              <TableCell align="right">Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {bannerData?.length > 0 ? (
              bannerData?.map((row, rowIndex) => (
                <TableRow key={row?.name}>
                  <TableCell component="th" scope="row">
                    {(page - 1) * 10 + (rowIndex + 1)}
                  </TableCell>
                  <TableCell>
                    {row?.imageUrl && (
                      <img
                        width={150}
                        height={150}
                        src={row?.imageUrl}
                        alt="banner"
                      />
                    )}
                  </TableCell>
                  <TableCell>{row?.title || "--"}</TableCell>
                  <TableCell align="right">
                    <div style={{ display: "flex", gap: "2px" }}>
                      <EditIcon
                        style={{
                          cursor: "pointer",
                          marginRight: 10,
                        }}
                        onClick={() => handleEdit(row?._id)}
                      />
                      <DeleteIcon
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          if (
                            window.confirm(
                              "Are you sure you wish to delete this amenity?"
                            )
                          )
                            onRemoveItem(row?._id);
                        }}
                      />
                    </div>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell style={{ border: "none" }}></TableCell>
                <TableCell style={{ border: "none" }}></TableCell>
                <TableCell
                  style={{
                    display: "flex",
                    fontSize: "20px",
                    fontWeight: "500",
                    alignItems: "center",
                    height: "200px",
                  }}
                >
                  No data
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <Pagination
        defaultCurrent={1}
        total={total}
        style={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
          height: "3em",
        }}
        onChange={(page) => setPage(page)}
      />
    </>
  );
}
