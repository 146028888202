import UploadFile from "components/Upload/UploadFile";
import React, { useEffect, useState } from "react";
import "../../assets/css/TrendingProject.css";
import { Button, Dropdown, Input, TextArea } from "antd";
import { useHistory } from "react-router-dom";

export default function TrendingProject() {
  const { TextArea } = Input;

  const navigate = useHistory();

  const [id, setId] = useState();
  const [projectName, setProjectName] = useState("");
  const [projectDescription, setProjectDescription] = useState("");
  const [location , setLocation] = useState("");
  const [projectDetails, setprojectDetails] = useState([]);
  const [units, setUnits] = useState([]);
  const [text, setText] = useState("");
  const [unitText, setUnitText] = useState([]);
  const [unitTitle, setUnitTitle] = useState("");
  const [amenities, setAmenities] = useState([]);
  const [amenitiesTitle, setAmenitiesTitle] = useState("");
  const [localities, setLocalities] = useState([]);
  const [localitieName, setLocalitieName] = useState("");
  const [localitieDistance, setLocalitiesDistance] = useState("");
  const [brochure, setBrochure] = useState([]);
  const [image, setImage] = useState();

  useEffect(() => {
    let data = localStorage.getItem("editTrendingData");
    let fdata = JSON.parse(data) || {};

    if (Object.keys(fdata).length > 0) {
      setProjectName(fdata?.projectName);
      setProjectDescription(fdata?.projectDescription);
      setLocation(fdata?.location);
      setprojectDetails(fdata?.projectDetails);
      setUnits(fdata?.units);
      setUnitTitle(fdata?.unitTitle);
      setAmenities(fdata?.amenities);
      setLocalities(fdata?.localities);
      setId(fdata?.id);
    }
  }, []);

  const addMoreHandler = () => {
    let data = [...projectDetails];
    let addMore = {
      text: text,
      id: Date.now(),
    };
    data.push(addMore);
    setprojectDetails(data);
    setText("");
  };

  const addMoreFilterHandler = (id) => {
    let data = [...projectDetails];
    let filteredData = data.filter((item) => item.id !== id);
    setprojectDetails(filteredData);
  };

  const textHandler = (event) => {
    setText(event.target.value);
  };

  const unitTextHandler = (event) => {
    setUnitText(event.target.value);
  };

  const unitHandler = () => {
    let data = [...units];
    let unitData = {
      unitDescriptiom: unitText,
      title: unitTitle,
      image: "",
      id: Date.now(),
    };
    data.push(unitData);
    setUnits(data);
    setUnitText("");
    setUnitTitle("");
  };

  const unitFilterHandler = (id) => {
    let data = [...units];
    let filteredData = data.filter((item) => item.id !== id);
    setUnits(filteredData);
  };

  const unitTitleHandler = (event) => {
    setUnitTitle(event.target.value);
  };

  const amenitiesTitleHandler = (event) => {
    setAmenitiesTitle(event.target.value);
  };

  const amenitiesFilterHandler = (id) => {
    let data = [...amenities];
    let filteredData = data.filter((item) => item.id !== id);
    setAmenities(filteredData);
  };

  const amenitiesHandler = () => {
    let data = [...amenities];
    let amenitiesData = {
      title: amenitiesTitle,
      image: "",
      id: Date.now(),
    };
    data.push(amenitiesData);
    setAmenities(data);
  };

  const localitiesFilterHandler = (id) => {
    let data = [...localities];
    let filteredData = data.filter((item) => item.id !== id);
    setLocalities(filteredData);
  };

  const localitiesTitleHandler = (event) => {
    setLocalitieName(event.target.value);
  };

  const localitiesDistanceHandler = (event) => {
    setLocalitiesDistance(event.target.value);
  };

  const localitiesHandler = () => {
    let data = [...localities];
    let localitiesData = {
      name: localitieName,
      distance: localitieDistance,
      id: Date.now(),
    };
    data.push(localitiesData);
    setLocalities(data);
    setLocalitieName("")
    setLocalitiesDistance("")
  };

  const projectNameHandler = (event) => {
    setProjectName(event.target.value);
  };

  const projectDescriptionHandler = (event) => {
    setProjectDescription(event.target.value);
  };

  const projectLocationHandler = (event) => {
    setLocation(event.target.value);
  };

  const submitHandler = () => {
    let ldata = localStorage.getItem("editTrendingData");
    let fdata = JSON.parse(ldata) || {};

    if (Object.keys(fdata).length > 0) {
      let udata = localStorage.getItem("projects");
      let localData = JSON.parse(udata);
      let filterdata = [];

      if (localData.length > 0) {
        filterdata = localData.filter((item, index) => {
          return item.id !== id;
        });
      }
      let data = [...filterdata] || [];
      let items = {
        id: id,
        projectName: projectName,
        projectDescription: projectDescription,
        location:location,
        projectDetails: projectDetails,
        units: units,
        text: text,
        unitText: unitText,
        unitTitle: unitTitle,
        amenities: amenities,
        amenitiesTitle: amenitiesTitle,
        localities: localities,
        localitieName: localitieName,
        localitieDistance: localitieDistance,
        image: image,
      };
      data.push(items);
      localStorage.setItem("projects", JSON.stringify(data));
      localStorage.removeItem("editTrendingData");
      navigate.push("/TrendingProjectListing");
    } else {
      let data1 = localStorage.getItem("projects");
      let data = JSON.parse(data1) || [];
      let items = {
        id: Date.now(),
        projectName: projectName,
        projectDescription: projectDescription,
        location:location,
        projectDetails: projectDetails,
        units: units,
        text: text,
        unitText: unitText,
        unitTitle: unitTitle,
        amenities: amenities,
        amenitiesTitle: amenitiesTitle,
        localities: localities,
        localitieName: localitieName,
        localitieDistance: localitieDistance,
        image: image,
      };
      data.push(items);
      localStorage.setItem("projects", JSON.stringify(data));
      navigate.push("/TrendingProjectListing");
    }
  };

  return (
    <div className="create-project-cntr">
      <div className="header-cntr">
        <h1>TRENDING PROJECT</h1>
        <p>Projects / Create New</p>
      </div>

      <div className="form-cntr">
        <h3>Create New Project</h3>

        <div className="feilds-cntr">
          <div className="feild">
            <label className="label-text">Project Name</label>
            <Input
              onChange={projectNameHandler}
              value={projectName}
              className="inp-feild"
              placeholder="Enter Project Name.."
            />
          </div>

          <div className="feild">
            <label className="label-text">Project Description</label>

            <TextArea
              maxLength={6}
              rows={4}
              className="inp-feild"
              placeholder="Enter Project Description.."
              value={projectDescription}
              onChange={projectDescriptionHandler}
            />
          </div>

          <div className="feild">
            <label className="label-text">Location</label>
            <Input
              onChange={projectLocationHandler}
              value={location}
              className="inp-feild"
              placeholder="Enter Location.."
            />
          </div>

          <div className="feild">
            <label className="label-text">About</label>
            <div className="details">
              <div>
                {projectDetails.map((item, index) => {
                  return (
                    <div className="details-cntr">
                      <p className="descriptiontrext"> {item.text}</p>
                      <Button
                        className=" deleteBtn"
                        onClick={() => {
                          addMoreFilterHandler(item.id);
                        }}
                      >
                        DELETE
                      </Button>
                    </div>
                  );
                })}
              </div>
              <div className="prj-details-cntr">
                <div className="project-details">
                  <Input
                    className="inp-feild"
                    placeholder="Project Details"
                    value={text}
                    onChange={textHandler}
                  />

                  <Button className="btn-bg" onClick={addMoreHandler}>
                    Add More
                  </Button>
                </div>
              </div>
            </div>
          </div>

          <div className="feild">
            <label className="label-text">Units</label>
            <div className="details">
              <div>
                {units.map((item, index) => {
                  return (
                    <div className="details-cntr">
                      <p className="descriptiontrext"> {item.title}</p>
                      <p className="descriptiontrext">
                        {" "}
                        {item.unitDescriptiom}
                      </p>

                      <Button
                        className=" deleteBtn"
                        onClick={() => {
                          unitFilterHandler(item.id);
                        }}
                      >
                        DELETE
                      </Button>
                    </div>
                  );
                })}
              </div>
              <div className="prj-details-cntr">
                <div className="project-details unit-cntr">
                  <Input
                    className=" unit-title"
                    placeholder="Unit Title"
                    value={unitTitle}
                    onChange={unitTitleHandler}
                  />
                  <Input
                    // maxLength={6}
                    rows={4}
                    className=" unit-detail"
                    placeholder="Unit Details"
                    value={unitText}
                    onChange={unitTextHandler}
                  />
                  <div className="upload-cntr">
                    <UploadFile />
                  </div>
                  <Button className="btn-bg unit-btn" onClick={unitHandler}>
                    Add Unit
                  </Button>
                </div>
              </div>
            </div>
          </div>

          <div className="feild">
            <label className="label-text">Amenities</label>
            <div className="details">
              <div>
                {amenities.map((item, index) => {
                  return (
                    <div className="details-cntr">
                      <p className="descriptiontrext"> {item.title}</p>
                      <Button
                        className=" deleteBtn"
                        onClick={() => {
                          amenitiesFilterHandler(item.id);
                        }}
                      >
                        DELETE
                      </Button>
                    </div>
                  );
                })}
              </div>
              <div className="prj-details-cntr">
                <div className="project-details unit-cntr">
                  <Input
                    className=" unit-title"
                    placeholder="Amenities Title"
                    value={amenitiesTitle}
                    onChange={amenitiesTitleHandler}
                  />

                  <div className="upload-cntr">
                    <UploadFile />
                  </div>
                  <Button
                    className="btn-bg unit-btn"
                    onClick={amenitiesHandler}
                  >
                    Add Amenities
                  </Button>
                </div>
              </div>
            </div>
          </div>

          <div className="feild">
            <label className="label-text">Localities</label>
            <div className="details">
              <div>
                {localities.map((item, index) => {
                  return (
                    <div className="details-cntr">
                      <p className="descriptiontrext"> {item.name}</p>
                      <p className="descriptiontrext"> {item.distance}</p>
                      <Button
                        className=" deleteBtn"
                        onClick={() => {
                          localitiesFilterHandler(item.id);
                        }}
                      >
                        DELETE
                      </Button>
                    </div>
                  );
                })}
              </div>
              <div className="prj-details-cntr">
                <div className="project-details unit-cntr">
                  <Input
                    className=" unit-title"
                    placeholder="Localitie name"
                    value={localitieName}
                    onChange={localitiesTitleHandler}
                  />

                  <Input
                    className=" unit-title"
                    placeholder="Localitie distance"
                    value={localitieDistance}
                    onChange={localitiesDistanceHandler}
                  />

                  {/* <div className="upload-cntr">
                    <UploadFile />
                  </div> */}
                  <Button
                    className="btn-bg unit-btn"
                    onClick={localitiesHandler}
                  >
                    Add Localities
                  </Button>
                </div>
              </div>
            </div>
          </div>
          <div className="feild">
            <label className="label-text">Brochure</label>
            <div className="upload-cntr">
              <UploadFile />
            </div>
          </div>
          <div className="feild">
            <label className="label-text" value={image}>
              Images
            </label>
            <div className="upload-cntr">
              <UploadFile />
            </div>
          </div>

          <div></div>

          <div>
            <div className="create-btn">
              <Button className="btn-bg" onClick={submitHandler}>
                Create Project
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
