import React from "react";
// import { withRouter } from "react-router-dom";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
class ChangePassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      oldPassword: "",
      open: true,
      newPassword: "",
      confirmPassword: "",
      oldPasswordError: "",
      newPasswordError: "",
      confirmPasswordError: "",
    };
  }
  clearForm() {
    this.setState({
      oldPassword: "",
      newPassword: "",
      confirmPassword: "",
      oldPasswordError: "",
      newPasswordError: "",
      confirmPasswordError: "",
    });
  }
  onChangeFieldsHandlerChangePassword = (e) => {
    if (e.target.id === "oldPassword") {
      this.setState({
        oldPassword: e.target.value,
        oldPasswordError: "",
      });
    } else if (e.target.id === "newPassword") {
      this.setState({
        newPassword: e.target.value,
        newPasswordError: "",
      });
    } else if (e.target.id === "confirmPassword") {
      this.setState({
        confirmPassword: e.target.value,
        confirmPasswordError: "",
      });
    }
  };
  onClickChangedPassword() {
    let isValid = true;
    if (this.state.oldPassword === "") {
      isValid = false;
      let password = "Please enter the old password";
      this.setState({ oldPasswordError: password });
    }
    if (this.state.newPassword === "") {
      isValid = false;
      let password = "Please enter the new password";
      this.setState({ newPasswordError: password });
    } else if (this.state.newPassword.length < 8) {
      isValid = false;
      let pass = "Your password must contain at least 8 characters";
      this.setState({ newPasswordError: pass });
    }
    if (
      this.state.confirmPassword === "" ||
      this.state.newPassword.length < 8
    ) {
      isValid = false;
      let pass = "Confirm new password";
      this.setState({ confirmPasswordError: pass });
    } else if (this.state.newPassword !== this.state.confirmPassword) {
      isValid = false;
      let confirmPassword = "Passwords doesn't match";
      this.setState({ confirmPasswordError: confirmPassword });
    }
    if (isValid) {
      console.log("Changed password successfull");
      this.clearForm();
    }
  }
  handleKeyPress = (e) => {
    if (e.charCode === 13) {
      this.onClickChangedPassword();
    }
  };
  handleClose = () => {
    this.props.onClose();
    this.clearForm();
  };
  render() {
    return (
      <div>
        <Dialog
          aria-labelledby="customized-dialog-title"
          open={this.props.open}
          id="dialog-view"
          fullWidth
          onClose={this.handleClose}
        >
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <div className="dialog-close-button" onClick={this.handleClose}>
              ×
            </div>
          </div>
          <DialogTitle
            id="alert-dialog-title"
            align="center"
            style={{ color: "orangered" }}
          >
            {"CHANGE PASSWORD"}
          </DialogTitle>
          <DialogContent align="center">
            <input
              type="password"
              id="oldPassword"
              className="textbox-forget-email"
              required
              onChange={this.onChangeFieldsHandlerChangePassword}
              value={this.state.oldPassword}
              onKeyPress={this.handleKeyPress}
              placeholder="Old password"
            />

            <div className="dialog-input-error-message">
              <span id="dialog-span-error-message">
                {this.state.oldPasswordError}
              </span>
            </div>
            <input
              type="password"
              id="newPassword"
              className="textbox-forget-email"
              required
              onChange={this.onChangeFieldsHandlerChangePassword}
              value={this.state.newPassword}
              onKeyPress={this.handleKeyPress}
              placeholder="New password"
            />

            <div className="dialog-input-error-message">
              <span id="dialog-span-error-message">
                {this.state.newPasswordError}
              </span>
            </div>
            <input
              type="password"
              id="confirmPassword"
              className="textbox-forget-email"
              required
              onChange={this.onChangeFieldsHandlerChangePassword}
              value={this.state.cofirmPassword}
              onKeyPress={this.handleKeyPress}
              placeholder="Confirm password"
            />

            <div className="dialog-input-error-message">
              <span id="dialog-span-error-message">
                {this.state.confirmPasswordError}
              </span>
            </div>
          </DialogContent>

          <DialogActions style={{ justifyContent: "center" }}>
            <button
              onClick={() => this.onClickChangedPassword()}
              id="save-password-button"
            >
              SAVE
            </button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}
export default ChangePassword;
