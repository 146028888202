import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import DeleteIcon from "@material-ui/icons/Delete";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import TopProject from "./TopProject";
import request from "../../core/apiClient/request";
import LoadingBar from "components/LoadingBar";
import { Pagination } from "antd";
import EditIcon from "@material-ui/icons/Edit";

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  tableContainer: {
    marginTop: 20,
  },
});

export default function TopProjectListing() {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [bannerData, setBannerData] = useState([]);
  const [circularProgOpen, setCircularProgOpen] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [editData, setEditData] = useState({});
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  function getBanners() {
    setCircularProgOpen(true);
    request
      .get(`/api/topProjects?page=${page}&size=10`)
      .then((res) => {
        if (res.status === 200) {
          setBannerData(res.data.topProjects);
          setTotal(res.data.total);
          console.log(res.data.topProjects);
        } else {
          console.log("something went wrong");
        }
        setCircularProgOpen(false);
      })
      .catch((e) => {
        console.log("something went wrong");
        setCircularProgOpen(false);
      });
  }
  useEffect(() => {
    getBanners();
  }, [page]);
  const onSaveBannerInfo = (details) => {
    if (isEdit) {
      request
        .patch(
          `/api/topProjects/${editData._id}`,
          {
            image: details.image,
            description: details.description,
            
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: ` ${localStorage.getItem("sessionKey")}`,
            },
          }
        )
        .then((res) => {
          if (res.status === 200) {
            getBanners();
            setOpen(false);
          }
        })
        .catch((e) => console.log("error", e));
    } else {
      request
        .post(
          "/api/topProjects",
          {
            image: details.image,
            description: details.description,
           
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: ` ${localStorage.getItem("sessionKey")}`,
            },
          }
        )
        .then((res) => {
          if (res.status === 200) {
            getBanners();
            setOpen(false);
          }
        })
        .catch((e) => console.log("error", e));
    }
  };

  const onRemoveItem = (id) => {
    request
      .delete(`/api/topProjects/${id}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: ` ${localStorage.getItem("sessionKey")}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          getBanners();
        } else {
          console.log("something went wrong");
        }
      })
      .catch((e) => console.log("error", e));
  };
  // function handleEdit(id) {
  //   const tempEditData = bannerData.filter((banner) => banner._id === id)[0];
  //   setOpen(true);
  //   setEditData(tempEditData);
  //   setIsEdit(true);
  // }
  return (
    <>
      <LoadingBar circularProgOpen={circularProgOpen} />
      <div
        style={{
          position: "relative",
          marginLeft: "auto",
          marginTop: "30px",
        }}
      >
        <Button
          variant="contained"
          color="primary"
          component="span"
          onClick={() => setOpen(true)}
        >
          Add New Projects
        </Button>
        <TopProject
          open={open}
          handleClose={() => {
            setOpen(false);
            setIsEdit(false);
          }}
          onSaveBannerInfo={onSaveBannerInfo}
          isEdit={isEdit}
          editData={editData}
        />
      </div>
      <TableContainer component={Paper} className={classes.tableContainer}>
        <Table
          className={classes.table}
          aria-label="simple table"
          showEmptyDataSourceMessage
        >
          <TableHead>
            <TableRow>
              <TableCell>S.N</TableCell>
              <TableCell style={{ marginLeft: "1em" }}>Image</TableCell>
              <TableCell>Description</TableCell>
              <TableCell align="right">Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {bannerData?.length > 0 ? (
              bannerData?.map((row, rowIndex) => (
                <TableRow key={row.name}>
                  <TableCell component="th" scope="row">
                    {rowIndex + 1}
                  </TableCell>
                  <TableCell>
                    {row?.image && (
                      <img
                        width={150}
                        height={150}
                        src={row.image}
                        alt="project"
                      />
                    )}
                  </TableCell>
                  <TableCell>{row?.description || "--"}</TableCell>
                  <TableCell align="right">
                    <div style={{ display: "flex", gap: "2px" }}>
                      {/* <EditIcon
                        style={{
                          cursor: "pointer",
                          marginRight: 10,
                        }}
                        onClick={() => handleEdit(row._id)}
                      /> */}
                      <DeleteIcon
                        onClick={() => {
                          if (
                            window.confirm(
                              "Are you sure you wish to delete this project?"
                            )
                          )
                            onRemoveItem(row._id);
                        }}
                        style={{ cursor: "pointer" }}
                      />
                    </div>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell style={{ border: "none" }}></TableCell>
                <TableCell style={{ border: "none" }}></TableCell>
                <TableCell
                  style={{
                    display: "flex",
                    fontSize: "20px",
                    fontWeight: "500",
                    alignItems: "center",
                    height: "200px",
                  }}
                >
                  No data
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <Pagination
        defaultCurrent={1}
        total={total}
        onChange={(page) => setPage(page)}
        style={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
          height: "3em",
        }}
      />
    </>
  );
}


