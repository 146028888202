import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";

const useStyles = makeStyles((theme) => ({
  input: {
    display: "none",
  },
}));

export default function UploadButtons({ onChange, buttonId }) {
  const classes = useStyles();

  return (
    <div>
      <input
        accept="image/*,video/*"
        className={classes.input}
        id={buttonId ? buttonId : "contained-button-file"}
        multiple
        type="file"
        onChange={onChange}
      />
      <label htmlFor={buttonId ? buttonId : "contained-button-file"}>
        <Button
          variant="contained"
          color="default"
          component="span"
          startIcon={<CloudUploadIcon />}
        >
          upload
        </Button>
      </label>
    </div>
  );
}
