import React from "react";
import { withRouter } from "react-router-dom";
import NestedRouter from "../../../core/routes/nestedRoutes";
import Header from "../../HeaderBar/HeaderBar";
import Expanded from "../../Sidebars/Expanded";
import "../../../assets/css/layout1.css";

class Layout1 extends React.Component {
  state = {
    showSideBar: false,
  };
  toggleSideBar = () => {
    this.setState({ showSideBar: this.state.showSideBar ? false : true });
  };
  render() {
    return (
      <div className="layout1-main-div">
        <div className="layout1-header-div">
          <Header toggleSideBar={this.toggleSideBar} {...this.props}></Header>
        </div>
        <div className="layout1-sidebar-div">
          <div className="layout1-expandedSidebar-div">
            <Expanded></Expanded>
          </div>
          <div
            className="layout1-nestedRouter-div"
            style={{ width: " calc(100% - 331px)" }}
          >
            <NestedRouter {...this.props} toggleSideBar={this.toggleSideBar} />
          </div>
        </div>
      </div>
    );
  }
}
export default withRouter(Layout1);
