import React from "react";
import "../../assets/css/Home.css";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import request from "../../core/apiClient/request";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import LoadingBar from "../../components/LoadingBar/index";
import { Checkbox, makeStyles } from "@material-ui/core";
import styled from "styled-components";
const Message = styled.div`
  max-height: 100px;
  overflow-y: scroll;
  ::-webkit-scrollbar {
    display: none;
  }
  min-width: 500px;

  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
`;
const Address = styled.div`
  max-height: 90px;
  overflow-y: scroll;
  ::-webkit-scrollbar {
    display: none;
  }

  -ms-overflow-style: none;
`;
class Enquiry extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      appointment: [],
      rowsPerPage: 15,
      page: 0,
      circularProgOpen: false,
      totalCount: 0,
    };
  }
  componentDidMount() {
    const PAGE = 1;
    const LIMIT = 15;
    this.getAppointment(PAGE, LIMIT);
  }
  getAppointment = async (page, limit = 15) => {
    try {
      this.setState({ circularProgOpen: true });
      let res = await request.get(`/api/contacts?size=${limit}&page=${page}`, {
        headers: {
          token: localStorage.sessionKey,
          Authorization: ` ${localStorage.getItem("sessionKey")}`,
        },
      });
      if (res.status >= 200 && res.status < 300) {
        this.setState({
          appointment: res?.data?.contacts || [],
          totalCount: res.data?.total || 0,
          circularProgOpen: false,
        });
      } else {
        this.props.handleSnackbar(
          true,
          "Something went wrong! please try again later.",
          "error"
        );

        this.setState({ circularProgOpen: false });
      }
    } catch (err) {
      this.props.handleSnackbar(
        true,
        "Something went wrong! please try again later.",
        "error"
      );

      this.setState({ circularProgOpen: false });
    }
    this.setState({ circularProgOpen: false });
  };
  handleDeleteEnquiry = (id) => {
    this.setState({ circularProgOpen: true });

    request
      .delete(`api/contacts/${id}`, {
        headers: {
          token: localStorage.sessionKey,
          Authorization: ` ${localStorage.getItem("sessionKey")}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          this.getAppointment(this.state.page + 1, this.state.rowsPerPage);
        } else {
          this.props.handleSnackbar(
            true,
            "Something went wrong! please try again later.",
            "error"
          );
        }
        this.setState({ circularProgOpen: false });
      })
      .catch((e) => {
        this.setState({ circularProgOpen: false });
        this.props.handleSnackbar(
          true,
          "Something went wrong! please try again later.",
          "error"
        );
      });
  };

  handleChangePage = (event, newPage) => {
    this.setState({ page: newPage });

    this.getAppointment(newPage + 1, this.state.rowsPerPage);
  };

  handleChangeRowsPerPage = (rowsPerPage) => {
    this.setState({ rowsPerPage });
    this.getAppointment(1, rowsPerPage);
    this.setState({ page: 0 });
  };
  handleRead = (e, id) => {
    this.setState({ circularProgOpen: true });

    request
      .patch(
        `/api/contacts/${id}`,
        { read: e.target.checked },
        {
          headers: {
            token: localStorage.sessionKey,
            Authorization: ` ${localStorage.getItem("sessionKey")}`,
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        if (res.status !== 200) {
          this.props.handleSnackbar(
            true,
            "Something went wrong! please try again later.",
            "error"
          );
        }
        this.getAppointment(this.state.page + 1, this.state.rowsPerPage);
        this.setState({ circularProgOpen: false });
      })
      .catch((e) => {
        this.setState({ circularProgOpen: false });
      });
  };

  render() {
    const { appointment, page, rowsPerPage, totalCount } = this.state;
    return (
      <div
        className="DashboardEmployee-main-div"
        style={{ padding: "15px", width: "100%" }}
      >
        <LoadingBar {...this.state}></LoadingBar>
        <span>Enquiry </span>

        <div className="search-report-refresh-div"></div>
        <div style={{ width: "100%" }}>
          <Paper style={{ width: "100%" }}>
            <TableContainer style={{ maxHeight: 540 }}>
              <Table
                className="enquiryTable"
                style={{ maxHeight: 540, width: "100%" }}
              >
                <TableHead style={{ backgroundColor: "#F4F4F4" }}>
                  <TableRow style={{ width: "100%" }}>
                    <TableCell></TableCell>
                    <TableCell>Sl. No.</TableCell>
                    <TableCell>Name</TableCell>
                    <TableCell>Email</TableCell>
                    <TableCell>Phone</TableCell>
                    {/* <TableCell>Address</TableCell> */}
                    <TableCell>Message</TableCell>
                    {/* <TableCell>Category</TableCell> */}
                    {/* <TableCell>Product Name</TableCell> */}

                    <TableCell>Action</TableCell>
                  </TableRow>
                </TableHead>
                {appointment.length > 0 ? (
                  <TableBody>
                    {appointment.map((contact, index) => (
                      <TableRow
                        className="hover-div"
                        role="checkbox"
                        hover
                        tabIndex={-1}
                        key={index}
                        elevation={4}
                        rowStyle={{ height: "50" }}
                        style={{
                          backgroundColor: contact?.read
                            ? "rgb(220,220,220)"
                            : "",

                          maxHeight: "70px",
                          overflow: "hidden !important",
                        }}
                      >
                        {" "}
                        <TableCell align="left">
                          <Checkbox
                            checked={contact?.read}
                            onChange={(e) => this.handleRead(e, contact._id)}
                          />
                        </TableCell>
                        <TableCell align="left">
                          {page * rowsPerPage + index + 1}
                        </TableCell>
                        <TableCell align="left">{contact.name}</TableCell>
                        <TableCell align="left">{contact.email}</TableCell>
                        <TableCell align="left">{contact.phone}</TableCell>
                        <TableCell align="left">
                          <Address>{contact.address}</Address>
                        </TableCell>
                        <TableCell style={{}} align="left">
                          <Message>{contact.message}</Message>
                        </TableCell>
                        <TableCell align="left">
                          {contact?.categoryId?.name}
                        </TableCell>
                        <TableCell align="left">
                          {contact?.productId?.name}
                        </TableCell>
                        <TableCell>
                          <DeleteOutlineIcon
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              if (
                                window.confirm(
                                  "Are you sure you wish to delete this category?"
                                )
                              )
                                this.handleDeleteEnquiry(contact._id);
                            }}
                          />
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                ) : (
                  <TableBody>
                    <TableRow
                      className="hover-div"
                      role="checkbox"
                      hover
                      tabIndex={-1}
                      elevation={4}
                    >
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell>Sorry..! No data found</TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
            <TablePagination
              style={{ paddingTop: "5px" }}
              rowsPerPageOptions={[15, 30, 60]}
              component="div"
              count={totalCount}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={this.handleChangePage}
              onChangeRowsPerPage={(event) =>
                this.handleChangeRowsPerPage(event.target.value)
              }
            />
          </Paper>
        </div>
      </div>
    );
  }
}
export default Enquiry;
