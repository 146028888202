import React, { useEffect, useState } from "react";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import EditIcon from "@material-ui/icons/Edit";
import { Button, Dropdown, Input, TextArea, Select } from "antd";
import { useHistory } from "react-router-dom";
import { Pagination } from "antd";
import { deleteProperty, getAllProperties } from "core/apiClient/apiFunctions";
// import "../../assets/css/ProjectListing.css";

export default function ProjectListing() {
  const navigate = useHistory();
  const [projectLiating, setProjectListing] = useState([]);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(1);

  
  function getProperties() {
    getAllProperties(page, 10)
      .then((res) => {
        if (res.status === 200) {
          setTotal(res.data.total);
          setProjectListing(res.data.properties);
        }
      })
      .catch((e) => {
        console.log("error", e);
      });
  }
  useEffect(() => {
    getProperties();
  }, [page]);
  const navigateToPage = () => {
    navigate.push("/Createproject");
  };

  const editHandler = (id) => {
    let data = [...projectLiating];

    let filteredData = data.filter((item) => item._id === id);
    localStorage.setItem("editData", JSON.stringify(filteredData[0]));
    navigate.push(`/Createproject?edit=true&id=${id}`);
  };

  const deleteHandler = (id) => {

    deleteProperty(id)
      .then((res) => {
        if (res.status === 200) {
          getProperties();
        }
      })
      .catch((e) => console.log("error", e));
  };

  return (
    <div>
      .<p style={{ margin: "1em" }}>Our Projects</p>
      <div
        className="crt-new-prjct"
        style={{
          display: "flex",
          justifyContent: "flex-end",
          alignItem: "flex-end",
        }}
      >
        <Button
          style={{
            height: "3rem",
            backgroundColor: "#3f51b5",
            color: "white",
            margin: "3rem",
          }}
          onClick={navigateToPage}
        >
          Create New Project
        </Button>
      </div>
      <div>
        <Paper style={{ width: "100%", backgroundColor: "#F4F4F4" }}>
          <TableContainer style={{ maxHeight: 540 }}>
            <Table>
              <TableHead style={{ backgroundColor: "#F4F4F4" }}>
                <TableRow>
                  <TableCell>Sl.No.</TableCell>
                  <TableCell> Project Name</TableCell>
                  <TableCell> Description</TableCell>
                  <TableCell>Project Type</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              {projectLiating.length > 0 ? (
                <TableBody>
                  {projectLiating.map((act, index) => (
                    <TableRow
                      style={{ backgroundColor: "white" }}
                      className="hover-div"
                      role="checkbox"
                      hover
                      tabIndex={-1}
                      key={index}
                      elevation={4}
                    >
                      <TableCell> {(page - 1) * 10 + (index + 1)}</TableCell>

                      <TableCell>{act?.title}</TableCell>
                      <TableCell>{act?.description}</TableCell>
                      <TableCell>{act?.type}</TableCell>
                      <TableCell>
                        <div
                          style={{
                            display: "flex",
                          }}
                        >
                          <EditIcon
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              editHandler(act._id);
                            }}
                          />
                          <DeleteOutlineIcon
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              if (
                                window.confirm(
                                  "Are you sure you wish to delete this category?"
                                )
                              )
                                deleteHandler(act._id);
                            }}
                          />
                        </div>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              ) : (
                <TableBody>
                  <TableRow
                    className="hover-div"
                    role="checkbox"
                    hover
                    tabIndex={-1}
                    elevation={4}
                  >
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell>Sorry..! No data found</TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableBody>
              )}
            </Table>
          </TableContainer>
          <Pagination
            defaultCurrent={1}
            total={total}
            pageSize={10}
            style={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
              height: "3em",
            }}
            onChange={(page) => setPage(page)}
          />
        </Paper>
      </div>
    </div>
  );
}
