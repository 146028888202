import React from "react";
import LoadingBar from "../../components/LoadingBar/index";
import request from "../../core/apiClient/request";
import Tooltip from "@material-ui/core/Tooltip";
import SearchIcon from "@material-ui/icons/Search";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import EditIcon from "@material-ui/icons/Edit";
import AddProduct from "./ProductDialog";

import ReactMarkdown from "react-markdown";

class Product extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      circularProgOpen: false,
      products: [],
      rowsPerPage: 15,
      page: 0,
      searchKey: "",
      open: false,
      selectedId: "",
      totalCount: 0,
      dialogId: "abc",
    };
  }

  componentDidMount() {
    const PAGE = 1;
    const LIMIT = this.state.rowsPerPage;
    this.getProduct(PAGE, LIMIT);
  }
  handlePushToProducts = (product) => {
    const productsArray = this.state.products;
    productsArray.push(product);
    this.setState({ products: productsArray });
  };
  handleClickOpen = () => {
    request.get();
    this.setState({
      open: true,
    });
  };
  handleLoading = (value) => {
    this.setState({ circularProgOpen: value });
  };
  handleLoader = (status = false) => {
    this.props.handleSnackbar(
      true,
      status
        ? "Product deleted Successfully"
        : "Something went wrong! please try again later.",
      status ? "success" : "error"
    );

    this.setState({ circularProgOpen: false });
  };

  getProduct = async (page, size) => {
    try {
      this.setState({ circularProgOpen: true });
      let res = await request.get(
        `v1/vendor-products?page=${page}&size=${size}`,
        {
          headers: {
            token: localStorage.sessionKey,
            Authorization: `Bearer ${localStorage.sessionKey}`,
          },
        }
      );

      if (res.status >= 200) {
        const result = res.data.vendorProducts;
        this.setState({
          products: result,
          totalCount: res.data.vendorProducts.length,
          circularProgOpen: false,
        });
      } else {
        this.handleLoader();
      }
    } catch (err) {
      this.handleLoader();
    }
  };
  handleChangePage = (event, newPage) => {
    this.setState({ page: newPage });
    this.getProduct(newPage + 1, this.state.rowsPerPage);
  };
  handleChangeRowsPerPage = (rowsPerPage) => {
    this.getProduct(this.state.page, rowsPerPage);
    this.setState({ rowsPerPage });
    this.setState({ page: 0 });
  };

  handleinputSelectBySearchValue = (e) => {
    this.setState({
      searchKey: e.target.value.replace(/[^a-zA-Z \d]/, "").replace(/ +/g, " "),
    });
    this.searchInputHandler(
      e.target.value.replace(/[^a-zA-Z \d]/, "").replace(/ +/g, " ")
    );
  };
  searchInputHandler = async (searchKey) => {
    let searchInputString = searchKey.trim();
    if (searchInputString) {
      try {
        let { data, status } = await request.get(
          `/v1/vendor-products/search?key=${searchInputString}`,
          {
            headers: {
              token: localStorage.sessionKey,
              Authorization: `Bearer ${localStorage.sessionKey}`,
            },
          }
        );
        if (status >= 200 && status < 300) {
          this.setState({
            products: data.vendorProduct || [],
            totalCount: data.count,
          });
        } else {
          this.handleLoader();
        }
      } catch (err) {
        this.handleLoader();
      }
    } else {
      this.getProduct(this.state.page, this.state.rowsPerPage);
    }
  };

  handleProductRemove = async (itemId) => {
    request
      .delete(`v1/vendor-product/${itemId}`, {
        headers: {
          token: localStorage.sessionKey,
          Authorization: `Bearer ${localStorage.sessionKey}`,
        },
      })
      .then((res) => {
        const filteredArray = this.state.products.filter(
          (product) => itemId !== product._id
        );
        this.setState({ products: filteredArray });
      })
      .catch((e) => console.log("error while deleting product", e));
  };
  onClose = () => {
    this.setState({ open: false, selectedId: "" });
  };
  handleEdit = (id) => {
    this.setState({ open: true, selectedId: id });
  };

  render() {
    return (
      <div className="DashboardEmployee-main-div" style={{ padding: "15px" }}>
        {this.state.open ? (
          <AddProduct
            open={this.state.open}
            onClose={this.onClose}
            selectedId={this.state.selectedId}
            activity={this.state.products}
            getProduct={() =>
              this.getProduct(this.state.page, this.state.rowsPerPage)
            }
            {...this.props}
            snackBar={this.props.handleSnackbar}
            loader={this.handleLoading}
            addProduct={this.handlePushToProducts}
          ></AddProduct>
        ) : (
          ""
        )}
        <LoadingBar {...this.state}></LoadingBar>
        <span>Products </span>
        <div className="search-report-refresh-div">
          <div className="search-container-activity">
            <input
              id="txtsearch"
              type="text"
              placeholder="Search by Product"
              name="search"
              value={this.state.searchKey}
              onChange={this.handleinputSelectBySearchValue}
            />
            <button
              type="submit"
              id="searchButton"
              style={{ color: "rgba(138, 132, 132, 0.8)" }}
            >
              <SearchIcon />
            </button>
          </div>

          <div
            style={{
              position: "relative",
              marginBottom: "88px",
              marginLeft: "auto",
            }}
          >
            <Tooltip
              placement="bottom"
              title={<span className="Tooltip-span">Add Product</span>}
            >
              <Fab
                style={{
                  background:
                    "linear-gradient(94.91deg, #ed1c24 5.75%, #ff656b 87.07%)",
                  color: "white",
                }}
                aria-label="add"
                onClick={this.handleClickOpen}
              >
                <AddIcon />
              </Fab>
            </Tooltip>
          </div>
        </div>
        <div>
          <Paper style={{ width: "100%" }}>
            <TableContainer style={{ maxHeight: 540 }}>
              <Table>
                <TableHead style={{ backgroundColor: "#F4F4F4" }}>
                  <TableRow>
                    <TableCell>Sl.No.</TableCell>
                    <TableCell>Name</TableCell>
                    <TableCell>Category</TableCell>
                    <TableCell>Sub Category</TableCell>
                    <TableCell>Product Description</TableCell>

                    <TableCell>Price</TableCell>
                    <TableCell>Action</TableCell>
                  </TableRow>
                </TableHead>
                {this.state.products.length > 0 ? (
                  <TableBody>
                    {this.state.products.map((act, index) => (
                      <TableRow
                        className="hover-div"
                        role="checkbox"
                        hover
                        tabIndex={-1}
                        key={index}
                        elevation={4}
                      >
                        <TableCell>
                          {(this.state.page * this.state.rowsPerPage,
                          this.state.page * this.state.rowsPerPage) +
                            index +
                            1}
                        </TableCell>

                        <TableCell>{act?.name}</TableCell>
                        <TableCell>
                          {act?.productCategory?.length !== 0
                            ? act.productCategory[0]?.name
                            : ""}
                        </TableCell>
                        <TableCell>
                          {act?.subCategory !== null
                            ? act?.subCategory?.name
                            : ""}
                        </TableCell>

                        <TableCell>
                          <ReactMarkdown>
                            {act?.description.toString()}
                          </ReactMarkdown>
                        </TableCell>

                        <TableCell>{act?.price}</TableCell>

                        <TableCell>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-around",
                            }}
                          >
                            <EditIcon
                              style={{ cursor: "pointer" }}
                              onClick={() => this.handleEdit(act?._id)}
                            />
                            <DeleteOutlineIcon
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                if (
                                  window.confirm(
                                    "Are you sure you wish to delete this product?"
                                  )
                                )
                                  this.handleProductRemove(act?._id);
                              }}
                            />
                          </div>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                ) : (
                  <TableBody>
                    <TableRow
                      className="hover-div"
                      role="checkbox"
                      hover
                      tabIndex={-1}
                      elevation={4}
                    >
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell>Sorry..! No data found</TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
            <TablePagination
              style={{ paddingTop: "5px" }}
              rowsPerPageOptions={[15, 30, 60]}
              component="div"
              count={this.state.totalCount}
              rowsPerPage={this.state.rowsPerPage}
              page={this.state.page}
              onChangePage={this.handleChangePage}
              onChangeRowsPerPage={(event) =>
                this.handleChangeRowsPerPage(event.target.value)
              }
            />
          </Paper>
        </div>
      </div>
    );
  }
}

export default Product;
