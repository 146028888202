import React from "react";
import "./App.css";
import { createStore } from "redux";
import { Provider } from "react-redux";
import rootReducer from "./core/redux/reducers/index";
import Routes from "./core/routes/index";
let store = createStore(rootReducer);

export default class App extends React.Component {
  render() {
    return (
      <Provider store={store}>
        <Routes />
      </Provider>
    );
  }
}


