import React from "react";
import profilePhoto from "../../assets/images/TeamPic.png";
import "../../assets/css/Expanded.css";
// import Avatar from "@material-ui/core/Avatar";
import List from "@material-ui/core/List";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import DashboardIcon from "@material-ui/icons/Dashboard";
import ListAltIcon from "@material-ui/icons/ListAlt";
import PermContactCalendarIcon from "@material-ui/icons/PermContactCalendar";
import PeopleAltIcon from "@material-ui/icons/PeopleAlt";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import PhotoIcon from "@material-ui/icons/Photo";
import AddPhotoAlternateIcon from "@material-ui/icons/AddPhotoAlternate";
import PhotoSizeSelectSmallIcon from "@material-ui/icons/PhotoSizeSelectSmall";

import { withRouter } from "react-router-dom";

class Expanded extends React.Component {
  render() {
    return (
      <div className="Expanded-main-div">
        <div className="profile-photo-name">
          <div>
            <img
              src={profilePhoto}
              alt="profile"
              id="profile-photo"
              style={{ borderRadius: "50%" }}
            ></img>
          </div>
          <div>
            <span id="user-profile-name">Golden Property</span> <br />
            <span id="user-profiled-department">Admin</span>
          </div>
        </div>
        <div style={{ width: "100%" }}>
          <List component="nav" aria-label="mailbox folders" align="center">
            <ListItem
              button
              onClick={() => {
                this.props.history.push("/");
              }}
              style={{
                background:
                  this.props.location.pathname === "/"
                    ? "rgba(158, 158, 158, 0.22)"
                    : "",
                color: this.props.location.pathname === "/" ? "##03A9F4" : "",
              }}
            >
              <ListItemAvatar>
                <DashboardIcon></DashboardIcon>
              </ListItemAvatar>
              <ListItemText primary={"Dashboard"} />
            </ListItem>

            <ListItem
              button
              onClick={() => {
                this.props.history.push("/AmenitiListing");
              }}
              style={{
                background:
                  this.props.location.pathname === "/AmenitiListing"
                    ? "rgba(158, 158, 158, 0.22)"
                    : "",
                color:
                  this.props.location.pathname === "/AmenitiListing"
                    ? "##03A9F4"
                    : "",
              }}
            >
              <ListItemAvatar>
                <ListAltIcon></ListAltIcon>
              </ListItemAvatar>
              <ListItemText primary={"Amenities"} />
            </ListItem>
            
            <ListItem
              button
              onClick={() => {
                this.props.history.push("/ProjectListing");
              }}
              style={{
                background:
                  this.props.location.pathname === "/ProjectListing"
                    ? "rgba(158, 158, 158, 0.22)"
                    : "",
                color:
                  this.props.location.pathname === "/ProjectListing"
                    ? "##03A9F4"
                    : "",
              }}
            >
              <ListItemAvatar>
                <ListAltIcon></ListAltIcon>
              </ListItemAvatar>
              <ListItemText primary={"Our Projects"} />
            </ListItem>

            <ListItem
              button
              onClick={() => {
                this.props.history.push("/TopProjectListing");
              }}
              style={{
                background:
                  this.props.location.pathname === "/TopProjectListing"
                    ? "rgba(158, 158, 158, 0.22)"
                    : "",
                color:
                  this.props.location.pathname === "/TopProjectListing"
                    ? "##03A9F4"
                    : "",
              }}
            >
              <ListItemAvatar>
                <ListAltIcon></ListAltIcon>
              </ListItemAvatar>
              <ListItemText primary={"Top Projects"} />
            </ListItem>


            <ListItem
              button
              onClick={() => {
                this.props.history.push("/add-banner");
              }}
              style={{
                background:
                  this.props.location.pathname === "/add-banner"
                    ? "rgba(158, 158, 158, 0.22)"
                    : "",
                color:
                  this.props.location.pathname === "/add-banner"
                    ? "##03A9F4"
                    : "",
              }}
            >
              <ListItemAvatar>
                <AddPhotoAlternateIcon />
              </ListItemAvatar>
              <ListItemText primary="Home Page Banner" />
            </ListItem>

            
            <ListItem
              button
              onClick={() => {
                this.props.history.push("/UpcomingProjectListing");
              }}
              style={{
                background:
                  this.props.location.pathname === "/UpcomingProjectListing"
                    ? "rgba(158, 158, 158, 0.22)"
                    : "",
                color:
                  this.props.location.pathname === "/UpcomingProjectListing"
                    ? "##03A9F4"
                    : "",
              }}
            >
              <ListItemAvatar>
                <AddPhotoAlternateIcon />
              </ListItemAvatar>
              <ListItemText primary="Upcoming Project" />
            </ListItem>

            <ListItem
              button
              onClick={() => {
                this.props.history.push("/TestimonalListing");
              }}
              style={{
                background:
                  this.props.location.pathname === "/TestimonalListing"
                    ? "rgba(158, 158, 158, 0.22)"
                    : "",
                color:
                  this.props.location.pathname === "/TestimonalListing"
                    ? "##03A9F4"
                    : "",
              }}
            >
              <ListItemAvatar>
                <ListAltIcon></ListAltIcon>
              </ListItemAvatar>
              <ListItemText primary={"Our Customer Speak"} />
            </ListItem>

            <ListItem
              button
              onClick={() => {
                this.props.history.push("/AwardsListing");
              }}
              style={{
                background:
                  this.props.location.pathname === "/AwardsListing"
                    ? "rgba(158, 158, 158, 0.22)"
                    : "",
                color:
                  this.props.location.pathname === "/AwardsListing"
                    ? "##03A9F4"
                    : "",
              }}
            >
              <ListItemAvatar>
                <ListAltIcon></ListAltIcon>
              </ListItemAvatar>
              <ListItemText primary={"Awards Recieved"} />
            </ListItem>

            <ListItem
              button
              onClick={() => {
                this.props.history.push("/BlocksListing");
              }}
              style={{
                background:
                  this.props.location.pathname === "/BlocksListing"
                    ? "rgba(158, 158, 158, 0.22)"
                    : "",
                color:
                  this.props.location.pathname === "/BlocksListing"
                    ? "##03A9F4"
                    : "",
              }}
            >
              <ListItemAvatar>
                <ListAltIcon></ListAltIcon>
              </ListItemAvatar>
              <ListItemText primary={"Blogs"} />
            </ListItem>

            <ListItem
              button
              onClick={() => {
                this.props.history.push("/ContactUs");
              }}
              style={{
                background:
                  this.props.location.pathname === "/ContactUs"
                    ? "rgba(158, 158, 158, 0.22)"
                    : "",
                color:
                  this.props.location.pathname === "/ContactUs"
                    ? "##03A9F4"
                    : "",
              }}
            >
              <ListItemAvatar>
                {/* <PeopleAltIcon></PeopleAltIcon> */}
                <PermContactCalendarIcon></PermContactCalendarIcon>
              </ListItemAvatar>
              <ListItemText primary={"Contact Us"} />
            </ListItem>

            {/* <ListItem
              button
              onClick={() => {
                this.props.history.push("/Enquiry");
              }}
              style={{
                background:
                  this.props.location.pathname === "/Enquiry"
                    ? "rgba(158, 158, 158, 0.22)"
                    : "",
                color:
                  this.props.location.pathname === "/Enquiry" ? "##03A9F4" : "",
              }}
            >
              <ListItemAvatar>
                <PeopleAltIcon></PeopleAltIcon>
              </ListItemAvatar>
              <ListItemText primary={"Enquiry"} />
            </ListItem> */}
          </List>
        </div>
      </div>
    );
  }
}
export default withRouter(Expanded);
