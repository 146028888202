import request from "./request";

function headers() {
  return {
    Authorization: localStorage.getItem("sessionKey"),
  };
}

export function postNewProject(payload) {
  return new Promise((resolve, reject) => {
    request
      .post("/api/properties", payload, {
        headers: headers(),
      })
      .then((res) => {
        resolve(res);
      })
      .catch((e) => reject(e));
  });
}
export function updateNewProject(payload,id) {
  console.log(id,"this is id")
  return new Promise((resolve, reject) => {
    request
      .patch(`/api/properties/${id}`, payload, {
        headers: headers(),
      })
      .then((res) => {
        resolve(res);
      })
      .catch((e) => reject(e));
  });
}


export function getAllProperties(page, size) {
  return new Promise((resolve, reject) => {
    request
      .get(`/api/properties?page=${page}&size=${size}`, {
        headers: headers(),
      })
      .then((res) => {
        resolve(res);
      })
      .catch((e) => reject(e));
  });
}

export function deleteProperty(id) {
  return new Promise((resolve, reject) => {
    request
      .delete(`/api/properties/${id}`, {
        headers: headers(),
      })
      .then((res) => {
        resolve(res);
      })
      .catch((e) => reject(e));
  });
}
