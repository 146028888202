import Home from "../../views/Home/HomePage";
import TestPage2 from "../../views/test2";
import Layout1 from "../../components/layouts/layout1/index";
import LoginPage from "../../views/LoginComponent/Login";
import ContactUs from "../../views/ContactUs/ContactUs";
import Enquiry from "../../views/Enquiry/Enquiry";
import Category from "../../views/Category/Category";
import Product from "../../views/Product/Product";
import Subscriber from "../../views/Subscriber";
import MainPageBanner from "../../views/MainPageBanner";
import CreateProject from "views/Project/CreateProject";
import ProjectListing from "views/Project/ProjectListing";
import TrendingProject from "views/TrendingProjects/TrendingProject";
import TrendingProjectListing from "views/TrendingProjects/TrendingProjectListing";
import Amenities from "views/Amenities/Amenities";
import AmenitiListing from "views/Amenities/AmenitiListing";
import UpcomingProject from "views/UpcomingProjects/UpcomingProject";
import UpcomingProjectListing from "views/UpcomingProjects/UpcomingProjectListing";
import Testimonal from "views/OurCustomerSpeaks/Testimonal";
import TestimonalListing from "views/OurCustomerSpeaks/TestimonalListing";
import Awards from "views/Awards/Awards";
import AwardsListing from "views/Awards/AwardsListing";
import Blocks from "views/Blocks/Blocks";
import BlocksListing from "views/Blocks/BlocksListing";
import TopProject from "views/TopProjects/TopProject";
import TopProjectListing from "views/TopProjects/TopProjectListing";

const Routes = [
  {
    path: "/login",
    page: LoginPage, // sending the component directly to the route
    config: {
      isAuthRequired: false,
      role: "",
    },
  },

  {
    path: "/", //main path
    layout: Layout1, // sending layout to the route
    config: {
      isAuthRequired: false,
      role: "",
    },
    subPaths: [
      {
        icon: "",
        name: "User Profile",
        path: "/", //this initial path should be '/'
        page: Home, //sending the page which has to be shown in the layout
        config: {
          isAuthRequired: false,
          role: "",
        },
      },
      {
        icon: "",
        name: "My Level",
        path: "/test",
        page: TestPage2,
        config: {
          isAuthRequired: false,
          role: "",
        },
      },
      {
        icon: "",
        name: "Ranking",
        path: "/test",
        page: TestPage2,
        config: {
          isAuthRequired: false,
          role: "",
        },
      },

      {
        icon: "",
        name: "",
        path: "/Category",
        page: Category,
        config: {
          isAuthRequired: false,
          role: "",
        },
      },
      {
        icon: "",
        name: "",
        path: "/Product",
        page: Product,
        config: {
          isAuthRequired: false,
          role: "",
        },
      },
      {
        icon: "",
        name: "",
        path: "/Amenities",
        page: Amenities,
        config: {
          isAuthRequired: false,
          role: "",
        },
      },
      {
        icon: "",
        name: "",
        path: "/AmenitiListing",
        page: AmenitiListing,
        config: {
          isAuthRequired: false,
          role: "",
        },
      },
      {
        icon: "",
        name: "",
        path: "/Createproject",
        page: CreateProject,
        config: {
          isAuthRequired: false,
          role: "",
        },
      },
      {
        icon: "",
        name: "",
        path: "/ProjectListing",
        page: ProjectListing,
        config: {
          isAuthRequired: false,
          role: "",
        },
      },
      {
        icon: "",
        name: "",
        path: "/TrendingProject",
        page: TrendingProject,
        config: {
          isAuthRequired: false,
          role: "",
        },
      },
      {
        icon: "",
        name: "",
        path: "/TrendingProjectListing",
        page: TrendingProjectListing,
        config: {
          isAuthRequired: false,
          role: "",
        },
      },
      {
        icon: "",
        name: "",
        path: "/TopProject",
        page: TopProject,
        config: {
          isAuthRequired: false,
          role: "",
        },
      },
      {
        icon: "",
        name: "",
        path: "/TopProjectListing",
        page: TopProjectListing,
        config: {
          isAuthRequired: false,
          role: "",
        },
      },
      {
        icon: "",
        name: "",
        path: "/UpcomingProject",
        page: UpcomingProject,
        config: {
          isAuthRequired: false,
          role: "",
        },
      },
      {
        icon: "",
        name: "",
        path: "/UpcomingProjectListing",
        page: UpcomingProjectListing,
        config: {
          isAuthRequired: false,
          role: "",
        },
      },
      {
        icon: "",
        name: "",
        path: "/Testimonal",
        page: Testimonal,
        config: {
          isAuthRequired: false,
          role: "",
        },
      },
      {
        icon: "",
        name: "",
        path: "/TestimonalListing",
        page: TestimonalListing,
        config: {
          isAuthRequired: false,
          role: "",
        },
      },
      {
        icon: "",
        name: "",
        path: "/Awards",
        page: Awards,
        config: {
          isAuthRequired: false,
          role: "",
        },
      },
      {
        icon: "",
        name: "",
        path: "/AwardsListing",
        page: AwardsListing,
        config: {
          isAuthRequired: false,
          role: "",
        },
      },
      {
        icon: "",
        name: "",
        path: "/Blocks",
        page: Blocks,
        config: {
          isAuthRequired: false,
          role: "",
        },
      },
      {
        icon: "",
        name: "",
        path: "/BlocksListing",
        page: BlocksListing,
        config: {
          isAuthRequired: false,
          role: "",
        },
      },

      {
        icon: "",
        name: "",
        path: "/ContactUs",
        page: ContactUs,
        config: {
          isAuthRequired: false,
          role: "",
        },
      },

      {
        icon: "",
        name: "",
        path: "/Enquiry",
        page: Enquiry,
        config: {
          isAuthRequired: false,
          role: "",
        },
      },
      {
        icon: "",
        name: "",
        path: "/Subscribe",
        page: Subscriber,
        config: {
          isAuthRequired: false,
          role: "",
        },
      },
      {
        icon: "",
        name: "",
        path:'/add-banner',
        page: MainPageBanner,
        config: {
          isAuthRequired: false,
          role: "",
        },
      }
    ],
  },
];
export default Routes;
