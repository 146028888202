const userReducer = (
  state = {
    tire: 0,
    userRecord: null,
    session: null
  },
  action
) => {
  switch (action.type) {
    case "LOGIN":
      console.log(action);
      return {
        ...state,
        userRecord: action.userRecord,
        session: action.session
      };
    case "UPDATE_SESSION":
      console.log(action);
      return { ...state, session: action.session };
    case "UPDATE_USER_RECORD":
      console.log(action);
      return { ...state, userRecord: action.userRecord };
    case "LOGOUT":
      return { ...state, session: null };
    default:
      return state;
  }
};

export default userReducer;
