import React from "react";
import { withRouter, Switch, Route } from "react-router-dom";
import "../../../assets/css/login.css";
// import SimpleSidebar from "../../Sidebars/simpleSidebar";

class Layout2 extends React.Component {
  render() {
    return (
      <div
        style={{
          overflowY: "auto",
          display: "flex",
          height: "100%",
          width: "100%"
        }}
      >
        <Switch>
          {this.props.subRoutes &&
            this.props.subRoutes.map((elem, routeIndex) => {
              return (
                <Route
                  key={routeIndex}
                  // exact
                  path={elem?.route}
                  render={() => <elem.page {...elem} />}
                ></Route>
              );
            })}
        </Switch>
      </div>
    );
  }
}
export default withRouter(Layout2);
