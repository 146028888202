import React from "react";
import "../../assets/css/Home.css";
import Tooltip from "@material-ui/core/Tooltip";
import SearchIcon from "@material-ui/icons/Search";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";
import Button from "@material-ui/core/Button";
import DialogContent from "@material-ui/core/DialogContent";
import TextField from "@material-ui/core/TextField";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import request from "../../core/apiClient/request";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import EditIcon from "@material-ui/icons/Edit";
import LoadingBar from "../../components/LoadingBar/index";
import UploadButton from "components/MainPageBanner/UploadButton";
import { te } from "date-fns/locale";

class Category extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      anchorEl: null,
      activity: [],
      rowsPerPage: 5,
      page: 0,
      searchKey: "",
      open: false,
      editbtn: false,
      editId: "",
      count: 0,
      topic: "",
      imageUrl: "",
      categoryImage: "",
      imageError: "",
      topicError: "",
      imageUrlError: "",
      circularProgOpen: false,
      currentCategory: "",
      subCategory: [],
      addedSubCategory: [],
      singleSubCategory: "",
      currentCategoryId: "",
      currentImageUrl: "",
      previousSubCategories: [],
      fileImage: "",
      subCategoryImage: "",
      subCategoryImageError: "",
      deleteSubcategoryFlag: true,

      editedId: [],
    };
  }
  componentDidMount() {
    const PAGE = 1;
    const LIMIT = this.state.rowsPerPage;

    this.getCategory(PAGE, LIMIT);
  }

  handleClickOpen = (page, limit) => {
    this.setState({
      open: true,
    });
  };
  handleUploadImage = (e) => {
    const image = e.target.files[0];
    const formData = new FormData();
    this.setState({ fileImage: image });
    formData.append("file", image, image.name);

    this.setState({
      imageError: "",
      circularProgOpen: true,
    });

    request
      .post("/v1/file-upload", formData, {
        headers: {
          token: localStorage.sessionKey,
          Authorization: `Bearer ${localStorage.sessionKey}`,
        },
      })
      .then((res) => {
        if (this.state.editbtn) {
          this.setState({
            currentImageUrl: res.data.filePath,
            circularProgOpen: false,
          });
          request
            .patch(
              `v1/product-category/${this.state.editId}`,
              { name: this.state.currentCategory, image: res.data.filePath },
              {
                headers: {
                  token: localStorage.sessionKey,
                  Authorization: `Bearer ${localStorage.sessionKey}`,
                },
              }
            )
            .then((res) => {
              if (res.status === 200) {
                this.props.handleSnackbar(
                  "true",
                  "Image updated successfully",
                  "success"
                );
              } else {
                this.props.handleSnackbar(
                  "true",
                  "something went wrong",
                  "error"
                );
              }
            })
            .catch((e) => console.log("something went wrong", e));
        } else {
          this.setState({
            imageUrl: res.data.filePath,
            circularProgOpen: false,
          });
        }
      })
      .catch((e) => {
        this.setState({ circularProgOpen: false });
      });
  };
  getCategory = async (
    page = this.state.page + 1,
    limit = this.state.rowsPerPage
  ) => {
    try {
      this.setState({ circularProgOpen: true });
      let res = await request.get(
        `/v1/product-categories?page=${page}&size=${limit}`,
        {
          headers: {
            token: localStorage.sessionKey,
            Authorization: `Bearer ${localStorage.sessionKey}`,
          },
        }
      );
      if (res.status === 200) {
        this.setState({
          activity: res.data.productCategoryList,
          count: res.data.count,
          circularProgOpen: false,
          imageUrl: "",
        });
      }
    } catch (err) {
      this.props.handleSnackbar(
        true,
        "Something went wrong! please try again later.",
        "error"
      );

      this.setState({ circularProgOpen: false });
    }
    this.setState({ circularProgOpen: false });
  };
  handleChangePage = (event, newPage) => {
    console.log("new page", newPage);
    this.setState({ page: newPage });
    this.getCategory(newPage + 1, this.state.rowsPerPage);
  };
  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: event.target.value });
    this.setState({ page: 0 });
    this.getCategory(this.state.page + 1, event.target.value);
  };

  handleinputSelectBySearchValue = (e) => {
    this.setState({
      searchKey: e.target.value.replace(/[^a-zA-Z \d]/, "").replace(/ +/g, " "),
    });
    this.filterData(
      e.target.value.replace(/[^a-zA-Z \d]/, "").replace(/ +/g, " ")
    );
  };
  filterData = async (searchKey) => {
    let searchInputString = searchKey.trim();

    try {
      let res = await request.get(
        `/v1/product-categories?searchText=${searchInputString}`,
        {
          headers: {
            token: localStorage.sessionKey,
            Authorization: `Bearer ${localStorage.sessionKey}`,
          },
        }
      );
      if (res.status === 200) {
        this.setState({
          activity: res.data.productCategoryList,
          circularProgOpen: false,
          imageUrl: "",
        });
      }
    } catch (err) {
      this.props.handleSnackbar(
        true,
        "Something went wrong! please try again later.",
        "error"
      );

      this.setState({ circularProgOpen: false });
    }
  };
  handleUpdateSubCategory(e, id, index, image) {
    if (id) {
      request
        .patch(
          `/v1/product-category/subcategory/${id}`,
          {
            name: e.target.value,
          },
          {
            headers: {
              token: localStorage.sessionKey,
              Authorization: `Bearer ${localStorage.sessionKey}`,
            },
          }
        )
        .then((res) => {})
        .catch((e) => console.log(e));
    } else {
      const tempAddedSubcategory = this.state.addedSubCategory;
      tempAddedSubcategory.forEach((item, index) => {
        if (item.image === image) {
          tempAddedSubcategory[index] = { name: e.target.value, image: image };
          return;
        }
      });
    }
  }
  handleUpdateCategory = () => {
    this.setState({ circularProgOpen: true });
    const id = this.state.currentCategoryId;
    request
      .patch(
        `v1/product-category/${id}`,
        { name: this.state.currentCategory },
        {
          headers: {
            token: localStorage.sessionKey,
            Authorization: `Bearer ${localStorage.sessionKey}`,
          },
        }
      )
      .then((res) => {
        if (res.status !== 200) {
          this.props.handleSnackbar(true, "something went wrong", "error");
        }
      })
      .catch((e) => console.log("error", e));
    console.log("added subcategory", this.state.addedSubCategory);

    request
      .post(
        "/v1/product-category",
        {
          name: this.state.addedSubCategory,
          parentId: this.state.currentCategoryId,
        },
        {
          headers: {
            token: localStorage.sessionKey,
            Authorization: `Bearer ${localStorage.sessionKey}`,
          },
        }
      )
      .then((res) => {
        if (res.status === 200) {
          this.props.handleSnackbar(
            true,
            `Successfully altered Sub Category of ${this.state.currentCategory}`,
            "success"
          );
          this.setState({
            addedSubCategory: [],
            currentCategory: "",
            currentCategoryId: "",
            subCategory: [],
            open: false,
            currentImageUrl: "",
            fileImage: "",
            editbtn: false,
            editedId: [],
          });
          this.getCategory();
        } else if (res.status === 500) {
          const subCategories = this.state.subCategory;
          this.setState({ subCategory: subCategories });
          this.props.handleSnackbar(true, "Duplicate Sub Category", "error");
        } else {
          this.props.handleSnackbar(true, "Something went wrong", "error");
        }

        this.setState({ circularProgOpen: false, editedId: [] });
      })
      .catch((e) => {
        this.setState({ circularProgOpen: false, editedId: [] });
      });
  };
  handleTopicRemove = async (itemId) => {
    this.setState({ circularProgOpen: true });
    try {
      const { status, data } = await request.delete(
        `/v1/product-category/${itemId}`,
        {
          headers: {
            token: localStorage.sessionKey,
            Authorization: `Bearer ${localStorage.sessionKey}`,
          },
        }
      );
      if (status >= 200 && status < 300 && data?.productCategory) {
        this.getCategory();
        this.setState({ circularProgOpen: false });
      }
    } catch (err) {
      console.log(err);
    } finally {
      this.setState({ circularProgOpen: false });
    }
  };
  componentWillUnmount() {
    clearTimeout(this.timer);
  }
  handleClose = () => {
    this.setState({
      open: false,

      editId: "",
      editbtn: false,
      topic: "",
      imageUrl: "",
      subCategory: [],
      topicError: "",
      imageUrlError: "",
      currentImageUrl: "",

      subCategoryError: "",
      editedId: [],
    });
  };

  onChangeFieldsHandler = (e) => {
    if (e.target.id === "topic") {
      let error = "";
      this.setState({
        topic: e.target.value,
        topicError: error,
      });
    } else if (e.target.id === "imageUrl") {
      let error = "";
      this.setState({
        imageUrl: e.target.value,
        imageUrlError: error,
      });
    }
  };
  handleDeleteSingleSubCategory = (index, id) => {
    let deletedArray = this.state.subCategory.filter((cat, i) => {
      if (index !== i) {
        return cat;
      }
    });
    if (id === undefined) {
      const indexOfAddedSubcategory =
        index -
        (this.state.subCategory.length - this.state.addedSubCategory.length);

      const deletedAddedSubcategory = this.state.addedSubCategory.filter(
        (cat, index) => index !== indexOfAddedSubcategory
      );

      this.setState({
        subCategory: deletedArray,
        addedSubCategory: deletedAddedSubcategory,
      });
      return;
    } else {
      this.setState({ circularProgOpen: true });
      request
        .delete(`/v1/product-category/${id}`, {
          headers: {
            token: localStorage.sessionKey,
            Authorization: `Bearer ${localStorage.sessionKey}`,
          },
        })
        .then((res) => {
          if (res.status === 200) {
            this.setState({ subCategory: deletedArray });
          } else {
            console.log("something went wrong");
          }
          this.setState({ circularProgOpen: false });
        })
        .catch((e) => {
          console.log("error while deleting");
          this.setState({ circularProgOpen: false });
        });
    }
  };

  handleAddSubCategoryList = () => {
    if (this.state.subCategoryImage === "") {
      this.setState({
        subCategoryImageError: "please upload subcategory image",
      });
      return;
    }
    let isDuplicate = false;
    if (this.state.singleSubCategory !== "") {
      let subCategoryArr = this.state.subCategory;
      let addedSubCategoryArr = this.state.addedSubCategory;

      subCategoryArr.forEach((sub) => {
        if (this.state.singleSubCategory === sub.name) {
          isDuplicate = true;
          this.props.handleSnackbar(true, "Duplicate subCategory", "error");
          return;
        }
      });
      if (!isDuplicate) {
        subCategoryArr.push({
          name: this.state.singleSubCategory,
          image: this.state.subCategoryImage,
        });

        addedSubCategoryArr.push({
          name: this.state.singleSubCategory,
          image: this.state.subCategoryImage,
        });

        this.setState({
          addedSubCategory: addedSubCategoryArr,
          subCategory: subCategoryArr,
        });
        this.setState({ singleSubCategory: "", subCategoryImage: "" });
      }
    }
  };
  handleEdit = (id) => {
    this.setState({ editbtn: true, open: true, editId: id });
    const category = this.state.activity.filter(
      (activity) => activity._id === id
    );
    const tempSubCategory =
      category.length === 0 ? [] : category[0]?.children?.productCategoryList;

    this.setState({
      currentCategory: category.length === 0 ? "" : category[0]?.name,
      currentImageUrl: category.length === 0 ? "" : category[0]?.image,
      currentCategoryId: category.length === 0 ? "" : category[0]?._id,
      subCategory: tempSubCategory,
    });
  };
  handleChangeCurrentCategory = (e) => {
    let value = e.target.value;
    this.setState({ currentCategory: value });
  };

  handleInsertCategory = async () => {
    let isValid = true;
    if (this.state.topic === "") {
      isValid = false;
      let error = "Enter Category Title";
      this.setState({
        topicError: error,
      });
    }

    if (this.state.imageUrl === "") {
      isValid = false;
      let error = "Please select a image";
      this.setState({ imageError: error });
    }
    let indexOfCategory = -1;
    this.state.activity.forEach((item, index) => {
      if (item.name === this.state.topic) {
        console.log("topic", this.state.topic);
        indexOfCategory = index;
      }
    });
    if (indexOfCategory !== -1) {
      isValid = false;
      this.props.handleSnackbar(true, "Duplicate category", "error");
    }
    if (isValid) {
      this.setState({ circularProgOpen: true });
      try {
        const { status, data } = await request.post(
          `/v1/product-category`,
          {
            name: this.state.topic,
            image: this.state.imageUrl,
          },
          {
            headers: {
              token: localStorage.sessionKey,
              Authorization: `Bearer ${localStorage.sessionKey}`,
            },
          }
        );

        if (status >= 200 && status < 300 && data?.productCategory?.name) {
          this.setState({
            name: "",
            subCategory: "",
            open: false,
            imageUrl: "",
            currentImageUrl: "",
            fileImage: "",
            currentCategory: "",
            topic: "",
          });
          this.getCategory();
          this.props.handleSnackbar(
            true,
            "Successfully added category",
            "success"
          );
        } else {
          this.props.handleSnackbar(true, "Something went wrong", "error");
        }
      } catch (err) {
        console.log(err);
      } finally {
        this.setState({ circularProgOpen: false });
      }
    }
  };
  handleEditAddedSubCategory = (e, category, index, id, image) => {
    const tempSubcategory = this.state.subCategory;

    tempSubcategory[index].name = e.target.value;

    this.setState({
      subCategory: tempSubcategory,
    });
  };
  uploadSubcategoryImage = (e) => {
    const image = e.target.files[0];
    const formData = new FormData();
    formData.append("file", image, image.name);
    this.setState({
      subCategoryImageError: "",
      circularProgOpen: true,
    });

    request
      .post("/v1/file-upload", formData, {
        headers: {
          token: localStorage.sessionKey,
          Authorization: `Bearer ${localStorage.sessionKey}`,
        },
      })
      .then((res) => {
        console.log("res", res.data.filePath);
        if (res.status === 200) {
          this.setState({ subCategoryImage: res.data.filePath });
        } else {
          this.props.handleSnackbar(true, "something went wrong", "error");
        }
        this.setState({
          circularProgOpen: false,
        });
      })
      .catch((e) => {
        console.log("something went wrong");
        this.setState({
          circularProgOpen: true,
        });
      });
  };

  render() {
    return (
      <div className="DashboardEmployee-main-div" style={{ padding: "15px" }}>
        <LoadingBar {...this.state}></LoadingBar>
        <span>Product Category</span>

        <div className="search-report-refresh-div">
          <div className="search-container-activity">
            <input
              id="txtsearch"
              type="text"
              placeholder="Search by keyword"
              name="search"
              value={this.state.searchKey}
              onChange={this.handleinputSelectBySearchValue}
            />
            <button
              type="submit"
              id="searchButton"
              style={{ color: "rgba(138, 132, 132, 0.8)" }}
            >
              <SearchIcon />
            </button>
          </div>

          <div
            style={{
              position: "relative",
              marginBottom: "88px",
              marginLeft: "auto",
            }}
          >
            <Tooltip
              placement="bottom"
              title={<span className="Tooltip-span">Add Category</span>}
            >
              <Fab
                style={{
                  background:
                    "linear-gradient(94.91deg, #ed1c24 5.75%, #ff656b 87.07%)",
                  color: "white",
                }}
                aria-label="add"
                onClick={this.handleClickOpen}
              >
                <AddIcon />
              </Fab>
            </Tooltip>
          </div>
        </div>
        <div>
          <Paper style={{ width: "100%" }}>
            <TableContainer style={{ maxHeight: 540 }}>
              <Table>
                <TableHead style={{ backgroundColor: "#F4F4F4" }}>
                  <TableRow>
                    <TableCell>Sl.No</TableCell>
                    <TableCell>Category Name</TableCell>
                    <TableCell>Image</TableCell>
                    <TableCell>Action</TableCell>
                  </TableRow>
                </TableHead>
                {this.state.activity.length > 0 ? (
                  <TableBody>
                    {this.state.activity.map((activity, index) => (
                      <TableRow
                        className="hover-div"
                        role="checkbox"
                        hover
                        tabIndex={-1}
                        key={index}
                        elevation={4}
                      >
                        <TableCell>
                          {(this.state.page * this.state.rowsPerPage,
                          this.state.page * this.state.rowsPerPage) +
                            index +
                            1}
                        </TableCell>
                        <TableCell>{activity?.name}</TableCell>
                        <TableCell>
                          {activity?.image === "" || !activity?.image ? (
                            ""
                          ) : (
                            <img
                              src={activity?.image}
                              style={{ height: "70px" }}
                              alt="category"
                            />
                          )}
                        </TableCell>

                        <TableCell>
                          {activity?.name === "headers" ? (
                            ""
                          ) : (
                            <div
                              style={{
                                display: "flex",
                              }}
                            >
                              <DeleteOutlineIcon
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                  if (
                                    window.confirm(
                                      "Are you sure you wish to delete this category?"
                                    )
                                  )
                                    this.handleTopicRemove(activity?._id);
                                }}
                              />
                              <EditIcon
                                style={{
                                  cursor: "pointer",
                                  marginRight: 10,
                                }}
                                onClick={() => this.handleEdit(activity?._id)}
                              />
                            </div>
                          )}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                ) : (
                  <TableBody>
                    <TableRow
                      className="hover-div"
                      role="checkbox"
                      hover
                      tabIndex={-1}
                      elevation={4}
                    >
                      <TableCell></TableCell>
                      <TableCell>Sorry..! No data found</TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
            <TablePagination
              style={{ paddingTop: "5px" }}
              rowsPerPageOptions={[5, 10, 15]}
              component="div"
              count={this.state.count}
              rowsPerPage={this.state.rowsPerPage}
              page={this.state.page}
              onChangePage={this.handleChangePage}
              onChangeRowsPerPage={this.handleChangeRowsPerPage}
            />
          </Paper>
        </div>
        <Dialog
          open={this.state.open}
          onClose={this.handleClose}
          aria-labelledby="form-dialog-title"
          fullWidth
        >
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <div
              style={{
                background: "rgb(221, 221, 221)",
                top: "0",
                right: "0",
                width: "25px",
                height: "25px",
                textAlign: " center",
                fontFamily: "arial",
                fontSize: "22px",
                cursor: "pointer",
                zIndex: "99992",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              onClick={this.handleClose}
            >
              ×
            </div>
          </div>
          <DialogContent>
            {!this.state.editbtn ? (
              <span className="span1" onClick={this.handleClose}>
                Add Category Of Product
              </span>
            ) : (
              <span className="span1" onClick={this.handleClose}>
                Update Category Of Product
              </span>
            )}
            <br />
            <div style={{ paddingTop: "20px" }}>
              <span style={{ color: "red" }}>{this.state.nameError}</span>
            </div>
            <br />
            <TextField
              autoFocus
              margin="dense"
              id="topic"
              label="Category Name"
              fullWidth
              onChange={
                this.state.editbtn
                  ? this.handleChangeCurrentCategory
                  : this.onChangeFieldsHandler
              }
              size="small"
              value={
                this.state.editbtn
                  ? this.state.currentCategory
                  : this.state.topic
              }
              placeholder="Enter Category Name"
            />
            <span style={{ color: "red" }}>{this.state.topicError}</span>
            <>
              <UploadButton
                onChange={this.handleUploadImage}
                buttonId="categoryImageUploader"
              />
              <span style={{ color: "red" }}>{this.state.imageError}</span>
            </>

            {this.state.fileImage !== "" ||
            this.state.currentImageUrl !== "" ? (
              <img
                alt="Category"
                src={
                  this.state.editbtn
                    ? this.state.currentImageUrl
                    : URL.createObjectURL(this.state.fileImage)
                }
                style={{ height: "70px" }}
              />
            ) : (
              ""
            )}
            <div
              style={{
                marginTop: "20px",
              }}
            >
              {this.state.editbtn ? <span>Sub Categories</span> : ""}
              {this.state.subCategory.length === 0
                ? ""
                : this.state.subCategory.map((category, index) => {
                    return (
                      <div key={index}>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <TextField
                            key={index}
                            value={
                              category.name
                                ? category.name
                                : category.subcategory
                            }
                            fullWidth
                            variant="outlined"
                            size="small"
                            style={{ marginTop: "10px", marginBottom: "10px" }}
                            onChange={(e) =>
                              this.handleEditAddedSubCategory(
                                e,
                                category,
                                index,
                                category._id,
                                category.image
                              )
                            }
                            onBlur={(e) => {
                              this.handleUpdateSubCategory(
                                e,
                                category._id,
                                index,
                                category.image
                              );
                            }}
                          />
                          <DeleteOutlineIcon
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              this.handleDeleteSingleSubCategory(
                                index,
                                category._id
                              );
                            }}
                          />
                        </div>
                        <div style={{ marginTop: "10px" }}>
                          <img
                            src={category.image ? category.image : ""}
                            height="100px"
                            width="100px"
                            alt="sub category image"
                          />
                        </div>
                      </div>
                    );
                  })}
            </div>
            {this.state.editbtn ? (
              <div style={{ marginTop: "10px" }}>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <TextField
                    autoFocus
                    margin="dense"
                    id="topic"
                    label={
                      this.state.subCategory.length === 0
                        ? "Add Sub Category"
                        : "Add Another Sub Category"
                    }
                    fullWidth
                    onChange={(e) =>
                      this.setState({ singleSubCategory: e.target.value })
                    }
                    value={this.state.singleSubCategory}
                    placeholder={
                      this.state.subCategory.length === 0
                        ? "Add Sub Category"
                        : "Add Another Sub Category"
                    }
                  />
                  <Button
                    variant="contained"
                    size="small"
                    onClick={this.handleAddSubCategoryList}
                    style={{ height: "30px", marginTop: "10px" }}
                  >
                    Add
                  </Button>
                </div>
                <div style={{ color: "red" }}>
                  {this.state.subCategoryImageError}
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "20px",
                    marginTop: "10px",
                  }}
                >
                  <UploadButton
                    onChange={this.uploadSubcategoryImage}
                    buttonId="subcategoryImageUploader"
                  />
                  {this.state.subCategoryImage && (
                    <img
                      src={this.state.subCategoryImage}
                      style={{ height: "100px", width: "100px" }}
                    />
                  )}
                </div>
              </div>
            ) : (
              ""
            )}
            <br />
            <br />
            <DialogActions>
              <Button onClick={this.handleClose} color="primary">
                Cancel
              </Button>
              {!this.state.editbtn ? (
                <Button onClick={this.handleInsertCategory} color="primary">
                  Add
                </Button>
              ) : (
                <Button onClick={this.handleUpdateCategory} color="primary">
                  Update
                </Button>
              )}
            </DialogActions>
          </DialogContent>
        </Dialog>
      </div>
    );
  }
}
export default Category;
