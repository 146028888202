import React from "react";
import { InboxOutlined, CloudUploadOutlined } from "@ant-design/icons";
import { message, Upload } from "antd";
const { Dragger } = Upload;

const UploadFile = ({ setFunction }) => {
  const props = {
    name: "file",
    multiple: true,
    action: `${process.env.REACT_APP_API_URL}/file`,
    onChange(info) {
      const { status } = info.file;
      if (status !== "uploading") {
        console.log(info.file, info.fileList);
      }
      if (status === "done") {
        const urls = info.fileList.map((file) => file?.response?.filePath);
        console.log("urls", urls);
        setFunction(urls);
        message.success(`${info.file.name} file uploaded successfully.`);
      } else if (status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
    onDrop(e) {
      console.log("Dropped files", e.dataTransfer.files);
    },
  };
  return (
    <Dragger {...props}>
      <p className="ant-upload-drag-icon">
        <CloudUploadOutlined />
      </p>
      <p className="ant-upload-text">
        Click or drag file to this area to upload
      </p>
    </Dragger>
  );
};
export default UploadFile;
