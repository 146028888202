import React, { useEffect, useState } from "react";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import EditIcon from "@material-ui/icons/Edit";
import { Button, Dropdown, Input, TextArea, Select } from "antd";
import { useHistory } from "react-router-dom";

export default function TrendingProjectListing() {
  const navigate = useHistory();
  const [trendingProjectListing, setTrendingProjectListing] = useState([]);

  useEffect(() => {
    let data = localStorage.getItem("projects");
    let projectData = JSON.parse(data) || [];
    setTrendingProjectListing([...projectData]);
  }, []);

  const navigateToPage = () => {
    navigate.push("/TrendingProject");
  };

  const editHandler = (id) => {
    let data = [...trendingProjectListing];
    let filteredData = data.filter((item) => item.id == id);
    localStorage.setItem("editTrendingData", JSON.stringify(filteredData[0]));
    navigate.push("/TrendingProject");
  };

  const deleteHandler = (id) => {
    let data = [...trendingProjectListing];
    let filteredData = data.filter((item) => item.id !== id);
    setTrendingProjectListing(filteredData);
    localStorage.setItem("projects", JSON.stringify(data));
    localStorage.setItem("projects", JSON.stringify(filteredData));
  };

  return (
    <div>
      .<p style={{ margin: "1em" }}>Trending Project</p>
      <div
        className="crt-new-prjct"
        style={{
          display: "flex",
          justifyContent: "flex-end",
          alignItem: "flex-end",
        }}
      >
        <Button
          style={{
            height: "3rem",
            backgroundColor: "#3f51b5",
            color: "white",
            margin: "3rem",
          }}
          onClick={navigateToPage}
        >
          Add Trending Project
        </Button>
      </div>
      <div>
        <Paper style={{ width: "100%", backgroundColor: "#F4F4F4" }}>
          <TableContainer style={{ maxHeight: 540 }}>
            <Table>
              <TableHead style={{ backgroundColor: "#F4F4F4" }}>
                <TableRow>
                  <TableCell>Sl.No.</TableCell>
                  <TableCell> Project Name</TableCell>
                  <TableCell>Description</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              {trendingProjectListing.length > 0 ? (
                <TableBody>
                  {trendingProjectListing.map((act, index) => (
                    <TableRow
                      className="hover-div"
                      role="checkbox"
                      hover
                      tabIndex={-1}
                      key={index}
                      elevation={4}
                    >
                      <TableCell>{index + 1}</TableCell>

                      <TableCell>{act?.projectName}</TableCell>
                      <TableCell>{act?.projectDescription}</TableCell>

                      <TableCell>
                        <div
                          style={{
                            display: "flex",
                          }}
                        >
                          <EditIcon
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              editHandler(act.id);
                            }}
                          />
                          <DeleteOutlineIcon
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              deleteHandler(act.id);
                            }}
                          />
                        </div>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              ) : (
                <TableBody>
                  <TableRow
                    className="hover-div"
                    role="checkbox"
                    hover
                    tabIndex={-1}
                    elevation={4}
                  >
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell>Sorry..! No data found</TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableBody>
              )}
            </Table>
          </TableContainer>
        </Paper>
      </div>
    </div>
  );
}
