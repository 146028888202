import React from "react";
import "../../assets/css/Home.css";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import request from "../../core/apiClient/request";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";

import { Checkbox } from "@material-ui/core";

import LoadingBar from "../../components/LoadingBar/index";
import { Pagination } from "antd";
class ContactUs extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      contact: [],
      rowsPerPage: 10,
      page: 0,
      circularProgOpen: false,
      totalCount: 0,
    };
  }
  componentDidMount() {
    this.getContacts(1, 10);
  }
  getContacts = async (page = 1, limit = 10) => {
    try {
      this.setState({ circularProgOpen: true });
      let res = await request.get(`/api/contacts?page=${page}&size=${limit}`, {
        headers: {
          token: localStorage.sessionKey,
          Authorization: ` ${localStorage.getItem("sessionKey")}`,
        },
      });
      if (res.status >= 200 && res.status < 300) {
        this.setState({
          contact: res.data.contacts,
          totalCount: res.data.total || 0,
          circularProgOpen: false,
        });
      } else {
        this.props.handleSnackbar(
          true,
          "Something went wrong! please try again later.",
          "error"
        );

        this.setState({ circularProgOpen: false });
      }
    } catch (err) {
      this.props.handleSnackbar(
        true,
        "Something went wrong! please try again later.",
        "error"
      );

      this.setState({ circularProgOpen: false });
    }
    this.setState({ circularProgOpen: false });
  };

  handleDeleteContact = (id) => {
    this.setState({ circularProgOpen: true });

    request
      .delete(`api/contacts/${id}`, {
        headers: {
          token: localStorage.sessionKey,
          Authorization: ` ${localStorage.getItem("sessionKey")}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          this.getContacts(this.state.page + 1, this.state.rowsPerPage);
        } else {
          this.props.handleSnackbar(
            true,
            "Something went wrong! please try again later.",
            "error"
          );
        }
        this.setState({ circularProgOpen: false });
      })
      .catch((e) => {
        this.setState({ circularProgOpen: false });
        this.props.handleSnackbar(
          true,
          "Something went wrong! please try again later.",
          "error"
        );
      });
  };
  handleChangePage = (event, newPage) => {
    this.getContacts(newPage + 1, this.state.rowsPerPage);
    this.setState({ page: newPage });
  };
  handleChangeRowsPerPage = (rowsPerPage) => {
    this.getContacts(1, rowsPerPage);
    this.setState({ rowsPerPage });
    this.setState({ page: 0 });
  };
  handlePageChange = (page) => {
    this.setState({ page: page });
    this.getContacts(page);
  };
  handleRead = (e, id) => {
    this.setState({ circularProgOpen: true });
    request
      .patch(
        `/api/contacts/${id}`,
        { read: e.target.checked },
        {
          headers: {
            token: localStorage.sessionKey,
            Authorization: ` ${localStorage.getItem("sessionKey")}`,
          },
        }
      )
      .then((res) => {
        if (res.status !== 200) {
          this.props.handleSnackbar(
            true,
            "Something went wrong! please try again later.",
            "error"
          );
        }
        this.getContacts(this.state.page + 1, this.state.rowsPerPage);
        this.setState({ circularProgOpen: false });
      })
      .catch((e) => {
        this.setState({ circularProgOpen: false });
      });
  };

  componentWillUnmount() {
    clearTimeout(this.timer);
  }
  render() {
    return (
      <div className="DashboardEmployee-main-div" style={{ padding: "15px" }}>
        <LoadingBar {...this.state}></LoadingBar>
        <span>Contact Us </span>
        <div className="search-report-refresh-div"></div>
        <div>
          <Paper style={{ width: "100%" }}>
            <TableContainer style={{ maxHeight: 540 }}>
              <Table>
                <TableHead style={{ backgroundColor: "#F4F4F4" }}>
                  <TableRow>
                    <TableCell></TableCell>
                    <TableCell style={{ textAlign: "center" }}>
                      Sl. No.
                    </TableCell>
                    <TableCell style={{ textAlign: "center" }}>Name</TableCell>
                    <TableCell style={{ textAlign: "center" }}>Email</TableCell>
                    <TableCell style={{ textAlign: "center" }}>Phone</TableCell>
                    <TableCell style={{ textAlign: "center" }}>
                      Message
                    </TableCell>
                    <TableCell style={{ textAlign: "center" }}>
                      Action
                    </TableCell>
                  </TableRow>
                </TableHead>
                {this.state.contact.length > 0 ? (
                  <TableBody>
                    {this.state.contact.map((Contact, index) => (
                      <TableRow
                        className="hover-div"
                        role="checkbox"
                        hover
                        tabIndex={-1}
                        key={index}
                        elevation={4}
                        style={{
                          backgroundColor: Contact?.read
                            ? "rgb(220,220,220)"
                            : "",
                        }}
                      >
                        <TableCell>
                          <Checkbox
                            checked={Contact?.read}
                            onChange={(e) => this.handleRead(e, Contact._id)}
                          />
                        </TableCell>
                        <TableCell style={{ textAlign: "center" }}>
                          {(this.state.page * this.state.rowsPerPage,
                          this.state.page * this.state.rowsPerPage) +
                            index +
                            1}
                        </TableCell>
                        <TableCell style={{ textAlign: "center" }}>
                          {Contact.name}
                        </TableCell>
                        <TableCell style={{ textAlign: "center" }}>
                          {Contact.email}
                        </TableCell>
                        <TableCell style={{ textAlign: "center" }}>
                          {Contact.phone}
                        </TableCell>
                        <TableCell style={{ textAlign: "center" }}>
                          {Contact.message}
                        </TableCell>
                        <TableCell>
                          <DeleteOutlineIcon
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              if (
                                window.confirm(
                                  "Are you sure you wish to delete this category?"
                                )
                              )
                                this.handleDeleteContact(Contact._id);
                            }}
                          />
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                ) : (
                  <TableBody>
                    <TableRow
                      className="hover-div"
                      role="checkbox"
                      hover
                      tabIndex={-1}
                      elevation={4}
                    >
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell>Sorry..! No data found</TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
            {/* <TablePagination
              style={{ paddingTop: "5px" }}
              rowsPerPageOptions={[15, 30, 60]}
              component="div"
              count={this.state.totalCount}
              rowsPerPage={this.state.rowsPerPage}
              page={this.state.page}
              onChangePage={this.handleChangePage}
              onChangeRowsPerPage={(event) =>
                this.handleChangeRowsPerPage(event.target.value)
              }
            /> */}
            <Pagination
              defaultCurrent={1}
              total={this.state.totalCount}
              onChange={this.handlePageChange}
              pageSize={10}
              style={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
                height: "3em",
              }}
            />
          </Paper>
        </div>
      </div>
    );
  }
}
export default ContactUs;
