// import UploadFile from "components/Upload/UploadFile";
// import React, { useEffect, useState } from "react";
// import "../../assets/css/Amenities.css";
// import { Button, Dropdown, Input, TextArea, Select } from "antd";
// import { useHistory, useLocation } from "react-router-dom";
// // import { DatabaseOutlined } from "@ant-design/icons";

// export default function Amenities() {
//   const navigate = useHistory();
//   const [id, setId] = useState(0);
//   const [amenities, setAmenities] = useState([]);
//   const [amenitiesTitle, setAmenitiesTitle] = useState("");

//   useEffect(() => {
//     let editData = localStorage.getItem("editAmenitiesData");
//     let parsedData = JSON.parse(editData) || [];
//     if (parsedData.length !== 0) {
//       setId(parsedData[0]?.id);
//       setAmenitiesTitle(parsedData[0].amenitiesTitle);
//     }
//   }, []);

//   // useEffect(() => {
//   //   let data = localStorage.getItem("editAmenitiesData");
//   //   let fdata = JSON.parse(data) || {};

//   //   if(Object.keys(fdata).length > 0){
//   //     // alert("yes")

//   //     setAmenities(fdata?.amenities)
//   //     setAmenitiesTitle(fdata?.amenitiesTitle)
//   //     setId(fdata?.id)
//   //   }

//   // }, []);

//   const amenitiesTitleHandler = (event) => {
//     setAmenitiesTitle(event.target.value);
//   };

//   const amenitiesFilterHandler = (id) => {
//     let data = [...amenities];
//     let filteredData = data.filter((item) => item.id !== id);
//     setAmenities(filteredData);
//   };

//   const amenitiesHandler = () => {
//     let data = [...amenities];
//     let amenitiesData = {
//       amenitiesTitle: amenitiesTitle,
//       amenities: amenities,
//       image: "",
//       id: Date.now(),
//     };
//     data.push(amenitiesData);
//     console.log("amenities Handler", data);
//     setAmenities(data);
//     setAmenitiesTitle("");
//   };

//   const submitHandler = () => {
//     let editData = localStorage.getItem("editAmenitiesData");
//     let parsedData = JSON.parse(editData) || [];
//     if (parsedData.length > 0) {
//       let savedData = localStorage.getItem("amenities");
//       let parseSData = JSON.parse(savedData);
//       let filterrData = [];
//       filterrData = parseSData.filter((item, index) => {
//         return item.id !== id;
//       });
//       let data = [...filterrData] || [];
//       let amenitiesData = {
//         id: id,
//         amenities: amenities,
//         amenitiesTitle: amenitiesTitle,
//         image: "",
//       };
//       data.push(amenitiesData);
//       localStorage.setItem("amenities", JSON.stringify(data));
//       localStorage.removeItem("editAmenitiesData");
//       navigate.push("/AmenitiListing");
//     } else {
//       let data1 = localStorage.getItem("amenities");
//       console.log(data1);
//       let data = JSON.parse(data1) || [];
//       // alert(JSON.stringify(data))
//       let amenitiesData = {
//         id: Date.now(),
//         amenities: amenities,
//         amenitiesTitle: amenitiesTitle,
//         image: "",
//       };

//       data.push(amenitiesData);
//       localStorage.setItem("amenities", JSON.stringify(data));
//       console.log(data);
//       navigate.push("/AmenitiListing");
//     }
//   };



//   return (
//     <div>
//       <h4 style={{ margin: "2em" }}>Amenities</h4>
//       <div className="feilds">
//         <div className="field-horizon">
//           <label className="label-text" style={{ margin: "1em" }}>
//             Amenities
//           </label>
//           <div className="details">
//             <div>
//               {amenities.map((item, index) => {
//                 return (
//                   <div className="details-cntr">
//                     <p className="descriptiontrext"> {item.amenitiesTitle}</p>
//                     <Button
//                       className=" deleteBtn"
//                       onClick={() => {
//                         amenitiesFilterHandler(item.id);
//                       }}
//                     >
//                       DELETE
//                     </Button>
//                   </div>
//                 );
//               })}
//             </div>
//             <div className="prj-details-cntr">
//               <div className="project-details unit-cntr">
//                 <Input
//                   className=" unit-title"
//                   placeholder="Amenities Title"
//                   value={amenitiesTitle}
//                   onChange={amenitiesTitleHandler}
//                 />

//                 <div className="upload-cntr">
//                   <UploadFile />
//                 </div>
//                 <Button className="btn-bg unit-btn" onClick={amenitiesHandler}>
//                   Add Amenities
//                 </Button>
//               </div>
//             </div>
//           </div>
//         </div>
//         <div className="create-btn">
//           <Button className="btn-bg" onClick={submitHandler}>
//             Add Amenities Project
//           </Button>
//         </div>
//       </div>
//     </div>
//   );
// }


import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";

import DialogTitle from "@material-ui/core/DialogTitle";
import UploadButton from "./UploadButton";
import request from "core/apiClient/request";

const Amenities = ({ open = false, handleClose, onSaveBannerInfo }) => {
  const [bannerImageUrl, setBannerImageUrl] = useState(null);
  const [bannerImageInfo, setBannerImageInfo] = useState(null);
  const [imageError, setImageError] = useState("");

  const [bannerDescription, setBannerDescription] = useState("");

  const onCreateNewBanner = () => {
    if (bannerImageUrl === null) {
      setImageError("please select a image");
    } else {
      onSaveBannerInfo({
        image: bannerImageUrl,
        description: bannerDescription,
        
      });
      setBannerImageUrl(null);
      setBannerImageInfo(null);
    }
  };

  const handleUploadImage = (e) => {
    const image = e.target.files[0];
    const formData = new FormData();
    formData.append("file", image);
    setBannerImageInfo(image);

    if (image !== undefined) {
      request
        .post("/file", formData, {
          headers: {
            token: localStorage.sessionKey,
            Authorization: ` ${localStorage.getItem('sessionKey')}`,
          },
        })
        .then((res) => {
          setBannerImageUrl(res.data.filePath);
          setImageError("");
        })
        .catch((e) => console.log("errors", e));
    }
  };

  

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
    >
      <div style={{ width: "clamp(200px,100vw,600px)" }}>
        <DialogTitle id="form-dialog-title"> Add New Banner</DialogTitle>
        <DialogContent>
          <div style={{ display: "flex", alignItems: "center" }}>
            <UploadButton onChange={(e) => handleUploadImage(e)} />
            <span style={{ color: "red" }}>{imageError}</span>
            <div
              style={{
                marginLeft: "10px",
                textOverflow: "ellipsis",
                maxWidth: "381px",
                overflow: "hidden",
                whiteSpace: "nowrap",
              }}
            >
              {bannerImageInfo?.name}
            </div>
          </div>
          <TextField
            margin="dense"
            placeholder="Add Description"
            label="Description"
            type="text"
            style={{ marginTop: "35px" }}
            fullWidth
            onChange={(e) => setBannerDescription(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={onCreateNewBanner} color="primary">
            Add
          </Button>
        </DialogActions>
      </div>
    </Dialog>
  );
};

export default Amenities;