import React from "react";
import Table from "../../components/MainPageBanner/Table";

const PageBanner = () => {
  return (
    <div className="DashboardEmployee-main-div" style={{ padding: "15px" }}>
      <span>Home Page Banner </span>
      <Table />
    </div>
  );
};

export default PageBanner;
